import { ApolloProvider } from "@apollo/react-common";
import getApolloClient from "./apollo-client";
import Frame from "./frame";
import React from "react";
import Auth from '../auth';
import { ApolloClient } from 'apollo-client';

import '../auth';
import ContentRouter from "./content-router";
import SplashScreen from "../components/splash-screen";
import { markVisit } from "../queries";

export default function App() {
  const [ apolloClient, setApolloClient ] = React.useState(null as ApolloClient<unknown> | null);

  React.useEffect(() => { (async () => {
    await Auth.initialize();
    setApolloClient(await getApolloClient());
    const laid = Auth.getLinkAttributionId();
    if (laid) {
      await markVisit(laid)
    }
  })() }, []);

  return apolloClient ? (
    <ApolloProvider client={apolloClient}>
      <Frame>
        <ContentRouter/>
      </Frame>
    </ApolloProvider>
  ) : (
    <SplashScreen/>
  );
}
