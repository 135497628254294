import React, { CSSProperties } from 'react';
import { CircularProgress } from "@material-ui/core";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import useImageDimensions from "../../util/use-image-dimensions";

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      borderRadius: theme.shape.borderRadius,
    },
    container: {
      position: 'relative',
    },
  })
);

type FixedSizeImageProps = {
  width: number;
  height: number;
  src: string;
  label: string;
  renderOverlay?: ({ bounds }: { bounds: ScaledImageBounds }) => React.ReactNode;
};

export default function FixedSizeImage({ width: canvasWith, height: canvasHeight, src, label, renderOverlay }: FixedSizeImageProps) {
  const classes = useStyles();
  const imageDimensions = useImageDimensions(src);

  const [ scaledImageBounds, containerBoundsStyle, imageSizeStyle ] = React.useMemo(() => {
    if (!imageDimensions) {
      return [];
    }

    const scale = Math.min(canvasWith / imageDimensions.width, canvasHeight / imageDimensions.height);
    const width = imageDimensions.width * scale;
    const height = imageDimensions.height * scale;
    const left = (canvasWith - width) / 2;
    const top = (canvasHeight - height) / 2;

    return [
      { scale, top, left, width, height },
      { top, left, width, height, position: 'absolute' },
      { width, height },
    ] as [ ScaledImageBounds, CSSProperties, CSSProperties ];
  }, [ imageDimensions, canvasWith, canvasHeight ]);

  const overlay = React.useMemo(() => {
    if (!scaledImageBounds || !renderOverlay) {
      return null;
    }

    return renderOverlay({ bounds: scaledImageBounds });
  }, [ renderOverlay, scaledImageBounds]);

  if (!imageDimensions || imageDimensions.forSrc !== src || !scaledImageBounds) {
    return (
      <CircularProgress size={12}/>
    );
  }

  return (
    <div className={ classes.container } style={ containerBoundsStyle }>
      <img
        alt={label}
        src={ src }
        className={ classes.image }
        style={ imageSizeStyle }
        draggable={false}
      />
      { overlay }
    </div>
  );
}
