import React from 'react';
import { Order } from "../types";
import { updateOrder, useListOrdersWithProductsQuery } from "../queries";
import { markEvent } from "../gtag";
import OrderModel from "../order/model";

export default function useShoppingCart() {
  const { loading, error, data } = useListOrdersWithProductsQuery();

  const shoppingCart = (!loading && !error && data) ? data.shoppingCart : null;
  const activeOrders = (!loading && !error && data) ? data.activeOrders : null;

  return React.useMemo(() => {
    const shoppingCartOrder: Order | null = shoppingCart ? ({
      createdAt: shoppingCart.createdAt,
      status: shoppingCart.status,
      items: OrderModel.getProducts(shoppingCart)
        .map(({ count, product }) => ({
          productId: product.id,
          count,
        })),
      }) : null;

    return ({
      loading,
      error,
      shoppingCart,
      activeOrders,

      async addProduct(productId: string) {
        if (!shoppingCartOrder) {
          throw new Error("shopping cart not ready");
        }
        const found = shoppingCartOrder.items.filter(item => item.productId === productId).length > 0;
        if (!found) {
          markEvent('add_product');
          await updateOrder({
            createdAt: shoppingCartOrder.createdAt,
            status: shoppingCartOrder.status,
            items: [ ...shoppingCartOrder.items, {
              productId,
              count: 1,
            } ],
          });
        }
      },

      async removeProduct(productId: string) {
        if (!shoppingCartOrder) {
          throw new Error("shopping cart not ready");
        }
        const newItems = shoppingCartOrder.items.filter(item => item.productId !== productId);
        const found = newItems.length !== shoppingCartOrder.items.length;
        if (found) {
          markEvent('remove_product');
          await updateOrder({
            createdAt: shoppingCartOrder.createdAt,
            status: shoppingCartOrder.status,
            items: newItems,
          });
        }
      },

      async setProductCount(productId: string, count: number) {
        if (!shoppingCartOrder) {
          throw new Error("shopping cart not ready");
        }
        let found = false;
        const newItems = shoppingCartOrder.items.map(item => {
          if (item.productId === productId) {
            found = true;
            return {
              productId,
              count,
            };
          }
          return item;
        });
        if (found) {
          markEvent('set_product_count');
          await updateOrder({
            createdAt: shoppingCartOrder.createdAt,
            status: shoppingCartOrder.status,
            items: newItems,
          });
        }
      },

      async cleanUpOrder() {
        if (!shoppingCartOrder) {
          throw new Error("shopping cart not ready");
        }
        const newItems = shoppingCartOrder.items.filter(item => item.count > 0);
        const changed = newItems.length !== shoppingCartOrder.items.length;
        if (changed) {
          await updateOrder({
            createdAt: shoppingCartOrder.createdAt,
            status: shoppingCartOrder.status,
            items: newItems,
          });
        }
      }
    });
  }, [ shoppingCart, activeOrders ]);
}
