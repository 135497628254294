import React from "react";

function useRevealedState(ref: React.MutableRefObject<HTMLElement | null>, threshold: number) {
  const [ revealed, setRevealed ] = React.useState(false);

  const observer = React.useMemo(() =>
    new IntersectionObserver(([ { intersectionRatio } ]) => {
      setRevealed(intersectionRatio > threshold);
    }, { threshold }),
    [ setRevealed ]
  );

  React.useEffect(() => {
    if (revealed || !ref.current) {
      return undefined;
    }

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ ref.current, observer, revealed ]);

  return revealed;
}

const fallback = (() => true) as typeof useRevealedState;

export default window.IntersectionObserver ? useRevealedState : fallback;
