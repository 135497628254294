import React from 'react';
import { CircularProgress } from "@material-ui/core";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import useImageDimensions from "../../util/use-image-dimensions";

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      borderRadius: theme.shape.borderRadius * 2
    },
  })
);

type SceneImageProps = {
  width: number;
  height: number;
  src: string;
  label: string;
};

export default function BoundedSizeImage({ width, height, src, label }: SceneImageProps) {
  const classes = useStyles();
  const imageDimensions = useImageDimensions(src);

  if (!imageDimensions || imageDimensions.forSrc !== src) {
    return (
      <CircularProgress size={12}/>
    );
  }

  const ratio = imageDimensions.width / imageDimensions.height;
  const canvasAspectRatio = width / height;

  let constrainedWidth: number | undefined;
  let constrainedHeight: number | undefined;

  if (ratio > canvasAspectRatio) {
    constrainedWidth = width;
  } else {
    constrainedHeight = height;
  }

  return (
    <img
      alt={label}
      src={src}
      className={ classes.image }
      style={{
        width: constrainedWidth,
        height: constrainedHeight,
      }}
    />
  );
}
