import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import BackButton from "../components/back-button";
import Typography from "@material-ui/core/Typography";
import LoadingScreen from "../components/loading-screen";
import Alert from "@material-ui/lab/Alert/Alert";
import useShoppingCart from "./use-shopping-cart";
import { markEvent } from "../gtag";
import OrderDisplay from "../components/order-display";
import CheckoutDialog from "../checkout";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import WAButton from "../components/wa-button";
import { buildOrderRoute, HOME_ROUTE, useRouting } from "../routing";
import { checkoutAndSendMessage, CheckoutType } from "../checkout/wa-checkout";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      padding: '7px 7px 100px 7px',
      flex: 1,
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
    },
    orderButtonContainer: {
      width: '100%',
      height: 100,
      paddingLeft: 52,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonsContainer: {
      marginTop: 15,
    },
    titleText: {
      textAlign: 'center',
      textTransform: 'uppercase',
      marginBottom: 15,
    },
    button: {
      marginBottom: 10
    },
    emptyCart: {
      padding: '15px, 0px',
      textAlign: 'center',
    },
    emptyCartIcon: {
      fontSize: 120,
    },
  }),
);

export default function ShoppingCartScreen() {
  const classes = useStyles();
  const [ checkout, setCheckout ] = React.useState(false);
  const { loading, error, shoppingCart, cleanUpOrder, activeOrders } = useShoppingCart();
  const [ cleanedUp, setCleanedUp ] = React.useState(false);
  const { setRoute } = useRouting();
  React.useEffect(() => {
    if (cleanedUp || !shoppingCart) {
      return;
    }

    setCleanedUp(true);
    // noinspection JSIgnoredPromiseFromCall
    cleanUpOrder();
  }, [ shoppingCart ]);

  const onStartCheckout = React.useCallback(async () => {
    markEvent('start_checkout');
    setCheckout(true);
  }, [ setCheckout ]);

  const onCancelCheckout = React.useCallback(() => {
    markEvent('cancel_checkout');
    setCheckout(false);
  }, [ setCheckout ]);

  const onImplicitCheckout = React.useCallback(async () => {
    if (shoppingCart) {
      await checkoutAndSendMessage(shoppingCart, CheckoutType.Implicit);
    }
  }, [ shoppingCart ]);

  const onContinueShopping = React.useCallback(() => {
    setRoute(HOME_ROUTE);
  }, [ setRoute ]);

  const onOpenActiveOrders = React.useCallback(() => {
    setRoute(buildOrderRoute({ orderCode: activeOrders![0].code! }));
  }, [ setRoute, activeOrders ]);

  if (loading) {
    return (<LoadingScreen/>);
  }

  if (error || !shoppingCart) {
    return (
      <div className={ classes.alertWrapper }>
        <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={ classes.container }>
        <div className={ classes.orderButtonContainer }>
          { (activeOrders && activeOrders.length) ? (
            <Button
              variant="contained"
              color="primary"
              onClick={ onOpenActiveOrders }
            >
              {
                activeOrders.length > 1 ?
                  `Tienes ${activeOrders.length } pedidos activos` :
                  `Tienes 1 pedido activo`
              }
            </Button>
          ) : null }
        </div>
        <Typography variant="h5" className={ classes.titleText }>
          Carrito de compras
        </Typography>
        <OrderDisplay order={ shoppingCart } readOnly={ false }/>
        <div className={ classes.buttonsContainer }>
          {
            shoppingCart.items.filter(({ product }) => !!product).length ?
              (
                <Button
                  className={ classes.button }
                  variant="contained"
                  color="primary"
                  onClick={ onStartCheckout }
                  fullWidth
                >
                  Iniciar compra
                </Button>
              ) :
              (
                <div className={ classes.emptyCart }>
                  <ShoppingCartOutlinedIcon className={ classes.emptyCartIcon }/>
                  <p>No has agregado artículos a tu carrito todavía</p>
                </div>
              )
          }
          <Button
            className={ classes.button }
            variant="contained"
            color="secondary"
            onClick={ onContinueShopping }
            fullWidth
          >
            Seguir comprando
          </Button>
        </div>
        <BackButton />
        <WAButton onClick={ onImplicitCheckout } dim={ checkout }/>
      </div>
      <CheckoutDialog shoppingCart={ shoppingCart } open={ checkout } onCancel={ onCancelCheckout } />
    </React.Fragment>
  );
}
