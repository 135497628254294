import React from 'react';
import RoutingContext, { RoutingContextValue } from './routing-context';
import { Router } from "./types";
import { Route } from "./routes";

type RoutingProviderProps = {
  router: Router;
  children: React.ReactNode;
};

export default function RoutingProvider({ router, children }: RoutingProviderProps) {
  const [ routingContextValue, setRoutingContextValue ] = React.useState(() => ({
    route: router.getRoute(),
    setRoute: router.setRoute
  }) as RoutingContextValue);

  React.useEffect(() => router.listenRoute((route: Route) => {
    setRoutingContextValue(() => ({
      route,
      setRoute: router.setRoute,
      popRoute: router.popRoute,
    }));
  }), []);

  return (
    <RoutingContext.Provider value={ routingContextValue }>
      { children }
    </RoutingContext.Provider>
  );
}
