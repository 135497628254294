import React, { CSSProperties } from 'react';
import { ProductSummary } from "../../types";
import ProductThumbnail from "../product-thumbnail";
import { buildProductRoute, useRouting } from "../../routing";
import { ImageVariant } from "@del-alto/shop-util";

type ProductSelectorProps = {
  size: number;
  imageVariant: ImageVariant;
  product: ProductSummary;
  style?: CSSProperties;
}

export default function ProductShowcase({ product, size, style, imageVariant }: ProductSelectorProps) {
  const { setRoute } = useRouting();
  const onSelectProduct = React.useCallback((productId: string) => {
    setRoute(buildProductRoute({ productId }));
  }, [ setRoute ]);

  const onViewProduct = React.useCallback(() => {
    onSelectProduct(product.id);
  }, [ onSelectProduct ]);

  return (
    <ProductThumbnail
      style={ style }
      size={ size }
      src={ product.uri }
      name={ product.name }
      price={ product.price }
      available={ product.available }
      tags={ product.tags }
      onClick={ onViewProduct }
      imageVariant={ imageVariant }
    />
  );
}
