export enum IdentityProvider {
  Cognito = 'COGNITO',
  Facebook = 'Facebook',
}

export type AuthConfig = {
  region: string;
  identityPoolId: string;
  clientId: string;
  redirectUri: string;
  authDomain: string;
  useUnAuthCredentials: boolean;
  refreshTokenDurationMillis: number;
}

export type AWSCognitoTokenData = {
  iss: string;
  iat: number; // (e.g. 1584890371)
  exp: number; // (e.g. 1584890371)
}

export type AWSCognitoTokens = {
  id_token: string;
  access_token: string;
  refresh_token: string;
}

export type AuthCredentialArgs = {
  userPoolId: string;
  idToken: string;
  formerIdentityId: string | null;
}
