import React from "react";

const NUMBER = '54-9-299-412-3545'.replace(/-/g, '');

export default function useSendWAMessage(message: string) {
  return React.useCallback((e: React.MouseEvent | null) => {
    if (e) {
      e.preventDefault();
    }
    sendWAMessage(message);
  }, [ message ]);
}

export function sendWAMessage(message: string) {
  const location = `https://wa.me/${NUMBER}?text=${ encodeURIComponent(message) }`;
  const opened = window.open(location);
  if (!opened) {
    window.location.href = location;
  }
}
