import React from 'react';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert/Alert";

import MeasureDimensions from "../components/measure-dimensions";
import { useSearchProductsQuery } from "../queries";
import { ProductSummary } from "../types";
import { buildSearchRoute, useRouting } from "../routing";
import BackButton from "../components/back-button";
import TextField from '@material-ui/core/TextField';
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import Board from "../components/board";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      padding: '0 7px',
      flex: 1,
      flexDirection: 'column'
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
    },
    messageWrapper: {
      width: '100%',
      position: 'relative',
    },
    scrollable: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'hidden',
      overflowY: 'scroll',
      flex: 1,
      justifyContent: 'space-between'
    },
    searchBoxContainer: {
      width: '100%',
      height: 100,
      paddingLeft: 52,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    searchBoxInput: {
      flex: 1,
      margin: '0 20px'
    },
    searchProgressBar: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
    }
  }),
);

type SearchScreenProps = {
  q: string;
}

export default function SearchScreen({ q }: SearchScreenProps) {
  const classes = useStyles();
  const { setRoute } = useRouting();

  const hasSearch = q !== '-';
  const [ searchTerm, setSearchTerm ] = React.useState(hasSearch ? q : null);
  const [ typingTimeout, setTypingTimeout ] = React.useState(null as number | null);

  const { loading, error, data } = useSearchProductsQuery({ filter: hasSearch ? { q } : null });

  const allProducts = (!data || !data.results) ? null : data.results.items;

  const products = React.useMemo(
    () => {
      if (!allProducts) {
        return [] as ProductSummary[];
      }

      return allProducts.filter(product => !!product.uri);
    },
    [ allProducts ],
  );

  const onSetSearchTerm = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (typingTimeout) {
      window.clearTimeout(typingTimeout);
    }

    const timeout = window.setTimeout(() => {
      setTypingTimeout(null);
      setRoute(buildSearchRoute({ q: value }));
    }, 500);

    setTypingTimeout(timeout);
  }, [ setSearchTerm, buildSearchRoute, setRoute, typingTimeout ]);

  if (error || !products) {
    return (
      <div className={ classes.alertWrapper }>
        <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
      </div>
    );
  }

  return (
    <React.Fragment>
      { loading ? <LinearProgress className={classes.searchProgressBar} /> : null }
      <div className={classes.container} >
        <div className={ classes.searchBoxContainer }>
          <TextField
            className={ classes.searchBoxInput }
            placeholder="¿Qué estás buscando?"
            autoFocus
            value={ searchTerm || '' }
            onChange={ onSetSearchTerm }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {
          (hasSearch && !loading && !products.length) ? (
            <div className={ classes.messageWrapper }>
              <Alert elevation={6} variant="filled" severity="info">No hay resultados</Alert>
            </div>
          ) : null
        }
        {
          products.length ? (
            <MeasureDimensions className={ classes.scrollable } render={({ width }) => (
              <Board width={ width } products={ products } />
            )}/>
          ) : null
        }
        <BackButton />
      </div>
    </React.Fragment>
  );
}
