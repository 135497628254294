import React from "react";

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useRouting } from "../../routing";

const useStyles = makeStyles(() =>
  createStyles({
    backButton: {
      position: 'absolute',
      backgroundColor: 'white',
      top: 25,
      left: 0,
    },
  }),
);

export default function BackButton() {
  const classes = useStyles();
  const { popRoute } = useRouting();

  return (
    <IconButton onClick={ popRoute } className={ classes.backButton }>
      <ArrowBackIosRoundedIcon />
    </IconButton>
  );
}
