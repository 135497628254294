import useScreenDimensions from "../util/use-screen-dimentions";
import { Dimensions } from "@del-alto/shop-util";
import React from "react";

export const SCENE_FRAME_PADDING: Dimensions = { height: 126, width: 14 };
const TARGET_HEIGHT = 0.9;
const MAX_FRAME_HEIGHT = 600;
const MIN_FRAME_HEIGHT = 400;

export function useSceneFrameDimensions(): Dimensions {
  const screenDimensions = useScreenDimensions();

  return React.useMemo(() => {
    const height = Math.min(MAX_FRAME_HEIGHT, Math.max(MIN_FRAME_HEIGHT,
      (screenDimensions.height - SCENE_FRAME_PADDING.height) * TARGET_HEIGHT
    ));
    const width = screenDimensions.width - SCENE_FRAME_PADDING.width;
    return { width, height };
  }, [ screenDimensions ]);
}
