import { pathToRegexp, compile, Key } from "path-to-regexp";
import { createBrowserHistory } from 'history';
import { DEFAULT_ROUTE, Route, ROUTES } from "./routes";
import { RouteListener, Router } from "./types";
import { pageView } from "../gtag";

const history = createBrowserHistory();

const routes = ROUTES.map((route) => {
  const keys: Key[] = [];
  const regexp = pathToRegexp(route.path, keys);
  const params = keys.map(key => key.name);

  const toPath = compile(route.path, { encode: encodeURIComponent });

  return ({
    id: route.id,
    regexp,
    params,
    toPath,
  });
});

function pathToRoute(path: string): Route {
  return routes.reduce((route, routeDefinition) => {
    if (route) {
      return route;
    }

    const match = routeDefinition.regexp.exec(path);
    if (!match) {
      return null;
    }

    const params = {};
    routeDefinition.params.map((param, i) => {
      params[param] = match[i + 1];
    });

    return {
      id: routeDefinition.id,
      ...params,
    } as Route;

  }, null) || DEFAULT_ROUTE;
}

function routeToPath(route: Route) {
  const routeDefinition = routes.filter(definition => definition.id === route.id)[0];

  return routeDefinition.toPath(route);
}

function getRoute(): Route {
  return pathToRoute(history.location.pathname);
}

function setRoute(route: Route): void {
  const newRoute = routeToPath(route);
  history.push(newRoute);
  pageView(newRoute);
}

function popRoute() {
  history.goBack();
}

let listening = false;
function listenRoute(listener: RouteListener): void {
  if (listening) {
    throw new Error(`Route listener already registered`);
  }
  listening = true;
  history.listen((location) => {
    listener(pathToRoute(location.pathname));
  });
}

export default {
  getRoute,
  setRoute,
  popRoute,
  listenRoute,
} as Router;
