import * as React from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from "@del-alto/mui-theme";
import App from "./app";
import { RoutingProvider, browserRouter } from "./routing";

export default function Root() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <RoutingProvider router={ browserRouter }>
        <App/>
      </RoutingProvider>
    </MuiThemeProvider>
  );
}
