import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BackButton from "../components/back-button";
import LoadingScreen from "../components/loading-screen";
import Alert from "@material-ui/lab/Alert/Alert";
import WAButton from "../components/wa-button";
import useSendWAMessage from "../util/use-send-wa-message";
import OrderDisplay from "../components/order-display";
import { useFindOrderByCodeQuery } from "../queries";
import { buildOrderURI } from "../routing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      padding: '25px 7px 100px 7px',
      flex: 1,
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
    },
    list: {
      // paddingTop: 80,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    shopButtonContainer: {
      width: '100%',
      paddingLeft: 52,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    },
    itemButton: {
      float: 'right',
    },
    messageBox: {
      padding: '0 15px',
      textAlign: 'center',
    },
  }),
);

type OrderScreenProps = {
  orderCode: string;
};
export default function OrderScreen({ orderCode }: OrderScreenProps) {
  const classes = useStyles();
  const { data, loading, error } = useFindOrderByCodeQuery({ code: orderCode });

  const waMessage = React.useMemo(() => {
    return `Hola, tengo una consulta sobre el siguiente pedido: ${buildOrderURI(orderCode)} .`;
  }, [ orderCode ]);

  const onSendMessage = useSendWAMessage(waMessage);

  if (loading) {
    return (<LoadingScreen/>);
  }

  if (error || !data) {
    return (
      <div className={ classes.alertWrapper }>
        <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
      </div>
    );
  }

  const order = data.order;
  return (
    <div className={ classes.container }>
      <div className={ classes.shopButtonContainer }>
        { order.status !== 'draft' ?
          (
            <p>
              <b>Gracias por tu pedido!</b><br/>
              Nos contactaremos para coordinar método de envío, forma de pago y facturación.<br/>
              Ante cualquier duda <a href="" onClick={ onSendMessage }>envianos tu consulta</a>.<br/>
              Número de pedido: <b>{ orderCode }</b>
            </p>
          ) : (
            <p>Código: <b>{ orderCode }</b></p>
          )
        }
      </div>
      { order.items.length ?(
        <OrderDisplay order={ order } readOnly={ true }/>
      ) : (
        <p>Este pedido no contiene productos.</p>
      )}
      <BackButton />
      <WAButton message={ waMessage } />
    </div>
  );
}
