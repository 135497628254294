import { Dimensions, ProductTag, CustomerInfo } from "@del-alto/shop-util";
import { ISO8601DateTime } from "@del-alto/data-types";

export type Product = ProductSummary & {
  code: string;
  description: string;
  keywords: string[] | null;
  class: string | null;
}

export type Scene = {
  id: string;
  uri: string;
  label: string;
  size?: Dimensions;
}

export type SceneWithTagsAndProducts = Scene & {
  tags: TagWithProduct[];
}

type Geometry = {
  x: number;
  y: number;
}

export type SearchResults = {
  items: ProductSummary[];
  total: number;
}

export type TagWithProduct = {
  index: number;
  product: ProductSummary | null;
  geometry: Geometry | null;
}

export type TagWithNonNullProduct = TagWithProduct & {
  product: Product;
  geometry: Geometry;
}

export type ProductSummary = {
  id: string;
  name: string;
  uri: string;
  price: number | null;
  available: boolean;
  tags: ProductTag[];
}

export type OrderItem = {
  productId: string;
  count: number;
}

export enum OrderStatus {
  New = 'new',
  Draft = 'draft',
  Review = 'review',
  Confirmed = 'confirmed',
  Completed = 'completed',
  Canceled = 'canceled',
}

export type Order = {
  createdAt: ISO8601DateTime;
  items: OrderItem[];
  status: OrderStatus;
}

export type OrderItemsInput = {
  createdAt: ISO8601DateTime;
  items: OrderItem[];
}

export type OrderStatusInput = {
  createdAt: ISO8601DateTime;
  status: OrderStatus;
  code?: string;
  hint?: string;
  customerInfo?: CustomerInfo;
}

export type OrderWithProducts = {
  createdAt: ISO8601DateTime;
  items: OrderItemWithProduct[];
  status: OrderStatus;
  code?: string;
}

export type PlaylistWithScenesAndProducts = {
  items: PlaylistItemWithScenesAndProducts[];
}

export type PlaylistItemWithScenesAndProducts = {
  product: ProductSummary | null;
  scene: SceneWithTagsAndProducts | null;
  size: PlaylistItemSize;
}

export enum PlaylistItemSize {
  Full = "full",
  Small = "small",
}

export type OrderItemWithProduct = {
  product: ProductSummary | null;
  count: number;
}

export type OrderItemWithNonNullProduct = OrderItemWithProduct & { product: ProductSummary }
