import React from 'react';
import * as Sentry from '@sentry/browser';
import Alert from "@material-ui/lab/Alert/Alert";
import { createStyles, makeStyles } from "@material-ui/core/styles";

type ErrorBoundaryState = { hasError: boolean };

const DEFAULT_MESSAGE = 'Hubo un error cargando datos.';

const useStyles = makeStyles(() =>
  createStyles({
    alertWrapper: {
      flex: 1,
      padding: 7,
    },
  })
);

export default function withErrorBoundary<P>(Render: React.ComponentType<P>, { message = DEFAULT_MESSAGE }: { message: string }) {
  return class ErrorBoundary extends React.Component<P, ErrorBoundaryState> {
    constructor(props: P) {
      super(props);
      this.state = {
        hasError: false,
      };
    }

    static getDerivedStateFromError() {
      return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }

    render() {
      const { hasError } = this.state;
      return hasError ? <ErrorMessage message={ message } /> : <Render { ...(this.props) } />;
    }
  }
}


function ErrorMessage({ message }: { message: string }) {
  const classes = useStyles();

  return (
    <div className={ classes.alertWrapper }>
      <Alert elevation={ 6 } variant="filled" severity="error">{ message }</Alert>
    </div>
  );
}
