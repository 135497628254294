import AWS, { config, Credentials } from 'aws-sdk';
import jwtDecode from 'jwt-decode';
import { AuthConfig, AWSCognitoTokenData, AWSCognitoTokens, IdentityProvider } from "./types";
import memoize from 'lodash/fp/memoize'

export default {
  initialize,
  clearCredentials,
  refreshCredentials,
  getCredentials,
  getLoginUrl,
  getClockSkew,
  isAuthorized,
  getUserId,
};
export { IdentityProvider };

const UN_AUTH_CREDENTIALS_DURATION = 60 * 60 * 1000; // 1 hour
const TOKEN_EXPIRATION_MARGIN = 5 * 60 * 1000; // 5 minutes
const decodeAuthToken = memoize(jwtDecode) as (token: string) => AWSCognitoTokenData;

let configuration: AuthConfig | null;
let credentialsPromise: Promise<Credentials | null> | null = null;
let currentCredentialsExpiration = Date.now();
let clockSkew = 0;
let authorized = false;

function getClockSkew() {
  return clockSkew;
}

function isAuthorized() {
  return authorized;
}

function initialize(authConfig: AuthConfig, code?: string | null): Promise<Credentials | null> {
  if (configuration) {
    throw new Error('Auth module was already configured');
  }

  config.region = authConfig.region;

  configuration = authConfig;
  return buildCredentials(code);
}

function refreshCredentials() {
  return buildCredentials();
}

function buildCredentials(code?: string | null): Promise<Credentials | null> {
  credentialsPromise = code ? primeAuthTokensFromSecrets({ code }) : buildCredentialsFromLocalStorage();
  credentialsPromise.then((credentials) => {
    config.credentials = credentials;
  });

  return credentialsPromise;
}

async function getCredentials(): Promise<Credentials | null> {
  if (!credentialsPromise) {
    throw new Error("Auth module hasn't been initialized");
  }

  if (currentCredentialsExpiration < (Date.now() - clockSkew) + TOKEN_EXPIRATION_MARGIN) {
    await refreshCredentials();
  }

  return credentialsPromise;
}

function getLoginUrl(identityProvider: IdentityProvider): string {
  const config = getConfig();
  return `${ config.authDomain }/oauth2/authorize?identity_provider=${ identityProvider }&response_type=CODE&client_id=${ config.clientId }&scope=openid&redirect_uri=${ config.redirectUri }`;
}

type AuthSecrets = {
  code?: string;
  refreshToken?: string;
}

async function primeAuthTokensFromSecrets(secrets: AuthSecrets): Promise<Credentials | null> {
  if (!secrets.code && !secrets.refreshToken) {
    throw new Error('Requires either code or refresh token');
  }
  const isRefresh = !secrets.code;
  const config = getConfig();
  const response = await window.fetch(`${config.authDomain}/oauth2/token`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },

    body: new URLSearchParams({
      // eslint-disable-next-line @typescript-eslint/camelcase
      grant_type: isRefresh ? 'refresh_token' : 'authorization_code',
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: config.clientId,
      // eslint-disable-next-line @typescript-eslint/camelcase
      redirect_uri: config.redirectUri,
      // eslint-disable-next-line @typescript-eslint/camelcase
      ...(isRefresh ? { refresh_token: secrets.refreshToken! } : { code: secrets.code! }),
    }),
  });

  const {
    id_token: idToken,
    access_token: accessToken,
    refresh_token: refreshToken,
  } = await response.json() as AWSCognitoTokens;

  const decodedToken = decodeAuthToken(idToken);
  const { iat, exp } = decodedToken;
  clockSkew = Date.now() - iat * 1000;
  currentCredentialsExpiration = exp * 1000;

  window.localStorage.setItem(`CognitoIdentityServiceProvider.${ config.clientId }.LastAuthUser`, idToken);
  window.localStorage.setItem(`CognitoIdentityServiceProvider.${ config.clientId }.AccessToken`, accessToken);

  if (refreshToken) {
    window.localStorage.setItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshToken`, refreshToken);
    window.localStorage.setItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshTokenExpirationDate`, new Date(iat * 1000 + config.refreshTokenDurationMillis).toISOString());
  }

  return buildCredentialsFromLocalStorage();
}

async function buildCredentialsFromLocalStorage(): Promise<Credentials | null> {
  const config = getConfig();
  const idToken = window.localStorage.getItem(`CognitoIdentityServiceProvider.${ config.clientId }.LastAuthUser`);

  let credentials;
  if (idToken) {
    try {
      const decodedToken = jwtDecode(idToken) as AWSCognitoTokenData;
      const { exp } = decodedToken;
      currentCredentialsExpiration = exp * 1000;
      if (currentCredentialsExpiration < (Date.now() - clockSkew) + TOKEN_EXPIRATION_MARGIN) {
        window.localStorage.removeItem(`CognitoIdentityServiceProvider.${ config.clientId }.LastAuthUser`);
        return await buildCredentialsFromRefreshToken();
      }

      const formerIdentityId = window.localStorage.getItem( `aws.cognito.identity-id.${ config.identityPoolId }`);

      credentials = buildAuthCredentials({
        idToken,
        formerIdentityId,
      });

      await credentials.getPromise();
      authorized = true;
    } catch(e) {
      clearCredentials();
    }
  }

  if (!credentials) {
    credentials = await buildUnAuthCredentials();
  }

  return credentials;
}

async function buildCredentialsFromRefreshToken(): Promise<Credentials | null> {
  const config = getConfig();
  const refreshTokenExpirationDate = window.localStorage.getItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshTokenExpirationDate`);
  const refreshToken = window.localStorage.getItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshToken`);

  if (refreshTokenExpirationDate && refreshToken && new Date(refreshTokenExpirationDate).getTime() > Date.now() - clockSkew + TOKEN_EXPIRATION_MARGIN) {
    await primeAuthTokensFromSecrets({ refreshToken });
  } else {
    window.localStorage.removeItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshTokenExpirationDate`);
    window.localStorage.removeItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshToken`);
  }

  return buildCredentialsFromLocalStorage();
}

async function buildUnAuthCredentials() {
  const config = getConfig();
  authorized = false;

  let credentials = null;
  if (config.useUnAuthCredentials) {
    credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.identityPoolId,
    });

    await credentials.getPromise();

    currentCredentialsExpiration = credentials.expireTime.getTime();
    clockSkew = Date.now() - (currentCredentialsExpiration - UN_AUTH_CREDENTIALS_DURATION);
  }

  return credentials;
}

function clearCredentials() {
  const config = getConfig();
  window.localStorage.removeItem(`aws.cognito.identity-id.${ config.identityPoolId }`);
  window.localStorage.removeItem(`CognitoIdentityServiceProvider.${ config.clientId }.LastAuthUser`);
  window.localStorage.removeItem(`CognitoIdentityServiceProvider.${ config.clientId }.AccessToken`);
  window.localStorage.removeItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshTokenExpirationDate`);
  window.localStorage.removeItem(`CognitoIdentityServiceProvider.${ config.clientId }.RefreshToken`);
}

type AuthCredentialArgs = {
  idToken: string;
  formerIdentityId: string | null;
}

function buildAuthCredentials({ idToken, formerIdentityId }: AuthCredentialArgs) {
  const config = getConfig();
  const decodedToken = decodeAuthToken(idToken);
  const userPoolId = decodedToken.iss.split('//')[ 1 ];

  return new AWS.CognitoIdentityCredentials({
    ...(formerIdentityId ? {
      IdentityId: formerIdentityId,
    } : {
      IdentityPoolId: config.identityPoolId,
    }),
    Logins: {
      [ userPoolId ]: idToken,
    },
  });
}
function getUserId() {
  const config = getConfig();
  return window.localStorage.getItem(`aws.cognito.identity-id.${ config.identityPoolId }`);
}

function getConfig(): AuthConfig {
  if (!configuration) {
    throw new Error('Auth module hasn\'t been configured yet');
  }

  return configuration;
}
