import { ProductSummary } from "../../types";
import React from "react";
import ProductShowcase from "../product-showcase";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ImageVariant } from "@del-alto/shop-util";

type BoardProps = {
  width: number;
  products: ProductSummary[];
};

const HORIZONTAL_PADDING = 20;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-around',
      flexFlow: 'wrap',
      alignItems: 'center',
    },
  }),
);

export default function Board({ width, products }: BoardProps) {
  const classes = useStyles();
  const thumbnailSize = Math.min(200, (width - HORIZONTAL_PADDING) / 2);

  return (
    <div className={ classes.root }>
      { products.map((product, i) => (
        <ProductShowcase
          key={ product.id }
          product={ product }
          size={ thumbnailSize }
          style={{ marginRight: (1 - i % 2) * HORIZONTAL_PADDING, marginBottom: HORIZONTAL_PADDING }}
          imageVariant={ ImageVariant.Half }
        />
      ))}
    </div>
  );
}
