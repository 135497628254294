import { sendWAMessage } from "../util/use-send-wa-message";
import { buildOrderURI } from "../routing";
import { markEvent } from "../gtag";
import { OrderStatus, OrderWithProducts } from "../types";
import { checkout, getOrderCode } from "../queries";
import { CustomerInfo } from "@del-alto/shop-util";

export enum CheckoutType {
  Implicit,
  Explicit,
}

const INTRO_MESSAGE = (name: string | null) => name ? `Hola, soy ${name}.` : 'Hola.';

const MESSAGES = {
  [CheckoutType.Implicit]: (code?: string) =>
    code ?
        `Tengo una consulta sobre el siguiente pedido: ${ buildOrderURI(code) } .` :
        'Tengo una consulta sobre mi pedido.',
  [CheckoutType.Explicit]: (code?: string) =>
    code ?
        `Deseo realizar el siguiente pedido: ${ buildOrderURI(code) } .` :
        'Deseo realizar un pedido.',
};

export async function checkoutAndSendMessage(shoppingCart: OrderWithProducts, type: CheckoutType, customerInfo?: CustomerInfo) {
  const code = shoppingCart.code;
  const name = (customerInfo && customerInfo.name) ? customerInfo.name : null;
  const implicitCheckout = type === CheckoutType.Implicit;
  if (code && (implicitCheckout || shoppingCart.status === OrderStatus.Review)) {
    sendWAMessage(buildMessage(type, name, code));
    return;
  }

  markEvent('make_draft_order');

  try {
    const code = await (implicitCheckout ?
      getOrderCode(shoppingCart) :
      checkout(shoppingCart, customerInfo!)
    );

    sendWAMessage(buildMessage(type, name, code));
  } catch (e) {
    sendWAMessage(buildMessage(type, name, code));
  }
}

function buildMessage(type: CheckoutType, name: string | null, code?: string) {
  return `${ INTRO_MESSAGE(name) } ${ MESSAGES[type](code) }`;
}
