import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";

type Dimensions = {
  width: number;
  height: number;
};

type MeasureDimensionsProps = {
  render: (dimensions: Dimensions) => React.ReactNode;
  className?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
    },
  })
);

export default function MeasureDimensions({ render, className }: MeasureDimensionsProps) {
  const [ dimensions, setDimensions ] = React.useState(null as Dimensions | null);

  const containerRef = React.useRef(null as HTMLDivElement | null);
  const classes = useStyles();

  React.useLayoutEffect(() => {
    const container = containerRef.current!;
    setDimensions({
      width: container.offsetWidth,
      height: container.offsetHeight
    });
  }, [ setDimensions, containerRef ]);

  return (
    <div
      ref={containerRef}
      className={ className || classes.container }
    >
      { dimensions && render(dimensions) }
    </div>
  );
}
