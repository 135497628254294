import {StockBehaviour, StockBehaviourTag} from "./types";
import {ProductTag} from "@del-alto/data-types";

export const STOCK_BEHAVIOUR_TAGS: StockBehaviourTag[] = [ ProductTag.Discontinued, ProductTag.Arrival, ProductTag.Requestable ];

export function getStockBehaviour(tags: ProductTag[] | null): StockBehaviour {
    const tagPool = tags || [];
    return STOCK_BEHAVIOUR_TAGS.reduce(
        (behaviour, tag) => behaviour || (tagPool.includes(tag) ? tag : null), null
    ) || 'normal';
}
