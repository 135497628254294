import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import useScreenDimensions from "../util/use-screen-dimentions";
import { ProductSummary } from "../types";
import ProductShowcase from "../components/product-showcase";
import { FixedSizeList } from 'react-window';
import { ImageVariant } from "@del-alto/shop-util";

type RelatedProductsStripProps = {
  products: ProductSummary[];
};

const HORIZONTAL_PADDING = 10;
const SIZE = 150;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: 25,
    },
  }),
);

export default function RelatedProductsStrip({ products }: RelatedProductsStripProps) {
  const classes = useStyles();
  const screenDimensions = useScreenDimensions();

  const itemRenderer = React.useMemo(() => {
    return function Product({ index, style }: { index: number; style: React.CSSProperties }) {
      const product = products[ index ];
      return (
        <ProductShowcase
          key={ product.id }
          size={ SIZE }
          product={ product }
          style={ style }
          imageVariant={ ImageVariant.Related }
        />
      );
    };
  }, [ products ]);

  return (
    <FixedSizeList
      className={ classes.root }
      width={ screenDimensions.width - HORIZONTAL_PADDING * 2 }
      height={ SIZE }
      itemSize={ SIZE + HORIZONTAL_PADDING }
      itemCount={ products.length }
      layout="horizontal"
    >{ itemRenderer }</FixedSizeList>
  );
}
