import React from "react";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../logo";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import { Badge } from "@material-ui/core";
import { HOME_ROUTE, SHOPPING_CART_ROUTE, useRouting } from "../../routing";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useListOrdersWithProductsLazyQuery } from "../../queries";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignSelf: 'center',
      width: '100%',
      maxWidth: 800,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: theme.spacing(2),
    },
    leftMenuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      position: 'absolute',
      width: 'calc(100% - 32px)',
      textAlign: 'center',
    },
    logoWrapper: {
      position: 'absolute',
      top: 7,
      left: 50,
    },
  }),
);

type TopBarProps = {
  onOpenMenuDrawer: () => void;
}
export default function TopBar({ onOpenMenuDrawer }: TopBarProps) {
  const classes = useStyles();
  const { setRoute } = useRouting();
  const [ initialize,{ data }] = useListOrdersWithProductsLazyQuery();
  const shoppingCartCount = (data && data.shoppingCart) ? data.shoppingCart.items.length : 0;

  React.useEffect(() => {
    setTimeout(initialize, 1000);
  }, []);

  const onOpenShoppingCart = React.useCallback(() => {
    setRoute(SHOPPING_CART_ROUTE);
  }, [ setRoute ]);

  const onGoToHome = React.useCallback(() => {
    setRoute(HOME_ROUTE);
  }, [ setRoute ]);

  return (
    <AppBar position="absolute" elevation={2}>
      <Toolbar className={ classes.root }>
        <IconButton edge="start" className={classes.leftMenuButton} onClick={ onOpenMenuDrawer } color="inherit">
          <MoreVertIcon />
        </IconButton>
        <div className={ classes.logoWrapper } onClick={ onGoToHome }>
          <Logo />
        </div>
        <div className={classes.grow} />
        <IconButton edge="end" className={classes.menuButton} onClick={ onOpenShoppingCart } color="inherit">
          <Badge badgeContent={ shoppingCartCount } color="secondary" variant={ shoppingCartCount === 1 ? 'dot' : 'standard' }>
            <ShoppingCartRoundedIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
