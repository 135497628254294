import React, { useCallback } from "react";

type Toggle = [boolean, () => void, () => void];

export default function useToggle(): Toggle {
  const [ active, setActive ] = React.useState(false);

  const activate = useCallback(() => {
    setActive(true);
  }, [ setActive ]);

  const deactivate = useCallback(() => {
    setActive(false);
  }, [ setActive ]);

  return [
    active,
    activate,
    deactivate,
  ];
}
