import history from '../history';
import Auth from '@del-alto/auth';
import * as Sentry from '@sentry/browser';

import {
  AUTH_DOMAIN,
  AWS_COGNITO_CODE_QUERY_PARAM,
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  CLIENT_ID,
  CUSTOM_LINK_ATTRIBUTION_QUERY_PARAM,
  REDIRECT_URI,
} from "./constants";

const REFRESH_TOKEN_DURATION = 180 * 24 * 3600 * 1000; // 180 days

const queryParams = (() => {
  const search = window.location.search || '';
  const hash = window.location.hash || '';

  return new URLSearchParams(search + hash.replace('#', search ? '&' : '?'));
})();

const linkAttributionId = queryParams.get(CUSTOM_LINK_ATTRIBUTION_QUERY_PARAM);
let sentryUserConfigured = false;

function getLinkAttributionId() {
  return linkAttributionId;
}

function setupUser() {
  const id = Auth.getUserId();
  if (id) {
    gtag('set', { 'user_id': id });
    if (!sentryUserConfigured) {
      Sentry.configureScope(function(scope) {
        scope.setUser({ id });
      });
      sentryUserConfigured = true;
    }
  }
}

export default {
  initialize,
  getLinkAttributionId,
}

async function initialize() {
  const queryParamCode = queryParams.get(AWS_COGNITO_CODE_QUERY_PARAM);

  if (queryParamCode) {
    history.replace(window.location.pathname);
  }

  return Auth.initialize({
    region: AWS_REGION,
    identityPoolId: AWS_IDENTITY_POOL_ID,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    authDomain: AUTH_DOMAIN,
    useUnAuthCredentials: true,
    refreshTokenDurationMillis: REFRESH_TOKEN_DURATION,
  }, queryParamCode)
    .then(() => {
      setupUser();
    });
}
