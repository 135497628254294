import React from "react";
import { measureImage } from "@del-alto/shop-util";

type ImageDimensions = {
  width: number;
  height: number;
  forSrc: string;
}

export default function useImageDimensions(src: string | null) {
  const [ imageDimensions, setImageDimensions ] = React.useState(null as ImageDimensions | null);

  React.useEffect(() => { (async () => {
    setImageDimensions(null);

    if (src) {
      const { width, height } = await measureImage(src);

      setImageDimensions({
        width,
        height,
        forSrc: src,
      });
    }
  })() }, [ setImageDimensions, src ]);

  return imageDimensions;
}
