import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import imageFile from "../logo/logo.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    splashScreen: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    logo: {
      width: '50%',
      borderRadius: '50%',
      border: '2px solid white',
      backgroundColor: 'white',
    },
  }),
);

export default function SplashScreen() {
  const classes = useStyles();

  return (
    <div className={ classes.splashScreen }>
      <img
        alt="Del Alto"
        src={imageFile}
        className={classes.logo}
      />
    </div>
  );
}
