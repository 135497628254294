import { createStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { CSSProperties } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    loadingWrapper: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

type LoadingScreenProps = { style?: CSSProperties };
export default React.forwardRef<HTMLDivElement, LoadingScreenProps>(function LoadingScreen({ style }: LoadingScreenProps, ref) {
  const classes = useStyles();

  const containerProps = style ? { style } : {};

  return (
    <div className={ classes.loadingWrapper } ref={ ref } { ...containerProps }>
      <CircularProgress />
    </div>
  );
})
