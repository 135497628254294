import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import waLogoFile from "./wa-logo.png";
import useSendWAMessage from "../../util/use-send-wa-message";
import classnames from 'classnames';

const useStyles = makeStyles(() =>
  createStyles({
    waButton: {
      opacity: 1,
      transition: 'opacity 1s',
      cursor: 'pointer',
    },
    waFixedButton: {
      position: 'fixed',
      bottom: 80,
      right: 20,
    },
    waIconLarge: {
      width: 64,
      height: 64,
    },
    waIconSmall: {
      width: 54,
      height: 54,
    },
  }),
);

type WAButtonProps = {
  message?: string;
  floating?: boolean;
  onClick?: (e: React.MouseEvent) => (Promise<unknown> | undefined);
  size?: "small" | "large";
  dim?: boolean;
  disabled?: boolean;
}

export default function WAButton({ message, floating = false, onClick, size = 'large', dim, disabled }: WAButtonProps) {
  const classes = useStyles();
  if (!message && !onClick) {
    throw new Error("Must provide onClick or message");
  }

  const [ loading, setLoading ] = React.useState(false);
  const sendMessage = useSendWAMessage(message || '');

  const clickCallback = React.useCallback(async (e) => {
    if (onClick) {
      const result = onClick(e);
      if (result) {
        setLoading(true);
        await result;
        setLoading(false);
      }
    } else {
      sendMessage(e);
    }
  }, [ setLoading, sendMessage, onClick ]);

  return (
    <div className={classnames(classes.waButton, !floating && classes.waFixedButton) } onClick={ disabled ? undefined : clickCallback } style={ dim ? { opacity: 0 } : {} }>
      { loading ? (
        <CircularProgress />
      ) : (
        <img
          alt="WhatsApp"
          src={ waLogoFile }
          className={ size === 'small' ? classes.waIconSmall : classes.waIconLarge }
        />
      )}
    </div>
  );
}
