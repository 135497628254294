import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from "apollo-link-context";
import Auth from '@del-alto/auth';

const CLOCK_SKEW_LIMIT = 4 * 60 * 1000;

export default function createClockSkewLink() {
  let clockSkew = Auth.getClockSkew();

  return ApolloLink.from([
    setContext((request, previousContext) => (
      clockSkew ? {
        ...previousContext,
        clockSkew,
      } : previousContext
    )),
    onError(({ operation, forward }) => {
      const context = operation.getContext();
      if (context.clockSkew) {
        return;
      }
      const httpResponse: Response = context.response;
      if (httpResponse && httpResponse.status === 400) {
        const dateHeader = httpResponse.headers && httpResponse.headers.get('date');
        const serverTime = dateHeader !== null && Date.parse(dateHeader);
        clockSkew = serverTime ? Date.now() - serverTime : 0;
        if (Math.abs(clockSkew) >= CLOCK_SKEW_LIMIT) {
          operation.setContext({
            ...context,
            clockSkew,
          });

          return forward(operation);
        }
      }

      return undefined;
    }),

  ]);
}
