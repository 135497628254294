import React from 'react';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert/Alert";

import MeasureDimensions from "../components/measure-dimensions";
import { useSearchProductsQuery } from "../queries";
import { ProductSummary } from "../types";
import BackButton from "../components/back-button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Board from "../components/board";
import { categoriesMap } from "@del-alto/product-categories";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
    },
    messageWrapper: {
      width: '100%',
      position: 'relative',
    },
    scrollable: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      justifyContent: 'space-between',
      flex: 1,
    },
    searchBoxContainer: {
      width: '100%',
      height: 100,
      paddingLeft: 52,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    searchBoxInput: {
      flex: 1,
      margin: '0 20px'
    },
    searchProgressBar: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
    }
  }),
);

type CategoryScreenProps = {
  category: string;
}

export default function CategoryScreen({ category: key }: CategoryScreenProps) {
  const classes = useStyles();

  const category = categoriesMap[key];
  const { loading, error, data } = useSearchProductsQuery({ filter: category.filter });

  const allProducts = (!data || !data.results) ? null : data.results.items;

  const products = React.useMemo(
    () => {
      if (!allProducts) {
        return [] as ProductSummary[];
      }

      return allProducts.filter(product => !!product.uri);
    },
    [ allProducts ],
  );

  if (error || !products) {
    return (
      <div className={ classes.alertWrapper }>
        <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
      </div>
    );
  }

  return (
    <React.Fragment>
      { loading ? <LinearProgress className={classes.searchProgressBar} /> : null }
      <div className={classes.container} >
        {
          (!loading && !products.length) ? (
            <div className={ classes.messageWrapper }>
              <Alert elevation={6} variant="filled" severity="info">No hay resultados</Alert>
            </div>
          ) : null
        }
        {
          products.length ? (
            <MeasureDimensions className={ classes.scrollable } render={({ width }) => (
              <Board width={ width } products={ products } />
            )}/>
          ) : null
        }
        <BackButton />
      </div>
    </React.Fragment>
  );
}
