import * as React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';

if (process.env.RELEASE_VERSION) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.RELEASE_VERSION,
  });
}

import Root from './root';

render(
  <Root/>,
  document.getElementById('root'),
);
