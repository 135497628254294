import React from 'react';
import { DEFAULT_ROUTE, Route } from "./routes";

export type RoutingContextValue = {
  route: Route;
  setRoute: (route: Route) => void;
  popRoute: () => void;
};

export default React.createContext({
  route: DEFAULT_ROUTE,
  setRoute: () => { /* do nothing */ },
  popRoute: () => { /* do nothing */ }
} as RoutingContextValue);
