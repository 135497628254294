import * as Sentry from '@sentry/browser';
import { ApolloClient } from 'apollo-client';
import createApolloClient from '@del-alto/appsync-apollo-client';
import { Order } from "../types";

let apolloClientPromise: Promise<ApolloClient<unknown>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function dataIdFromObject(data: any) {
  if (data.__typename) {
    // noinspection JSRedundantSwitchStatement
    switch (data.__typename) {
      case 'Order': {
        const order: Order = data;
        if (order.createdAt) {
          return `${ data.__typename }:${ order.createdAt }`;
        }
      }
    }
  }
  return null;
}

async function buildApolloClient() {
  return createApolloClient(
    {
      config: {
        dataIdFromObject,
        cacheRedirects,
      },
      errorCallback: (operation, message, extras) => {
        Sentry.withScope((scope) => {
          scope.setTag("graphql.operation", operation.operationName);
          scope.setExtras(extras);
          Sentry.captureMessage(message);
        });
      },
    }
  );
}

export default function getApolloClient() {
  if (!apolloClientPromise) {
    apolloClientPromise = buildApolloClient();
  }

  return apolloClientPromise;
}

const cacheRedirects = {
  Query: {
    getScene: (root: unknown, args: { id: string }, { getCacheKey }: CacheResolverContext): unknown =>
     getCacheKey({ __typename: 'Scene', id: args.id }),
    getProduct: (root: unknown, args: { id: string }, { getCacheKey }: CacheResolverContext): unknown =>
      getCacheKey({ __typename: 'Product', id: args.id }),
  },
};

type CacheResolverContext = {
  getCacheKey: ({ __typename, id }: { __typename: string; id: string }) => unknown;
};
