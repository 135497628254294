import React from "react";
import min from "lodash/min";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Badge} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import {buildProductRoute, useRouting} from "../../routing";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {OrderItemWithNonNullProduct, OrderWithProducts} from "../../types";
import useShoppingCart from "../../shopping-cart/use-shopping-cart";
import {
    formatPrice,
    getCdnUri,
    ImageVariant,
    getBestProductPrice,
    getBestProductInstallments,
    getBestProductDiscount,
    isHotSale, getBestMixPrice,
} from "@del-alto/shop-util";
import withErrorBoundary from "../with-error-boundary";
import Order from "../../order/model";
import {getBestPromoMix} from "@del-alto/shop-util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    itemButton: {
      float: 'right',
    },
    salePrice: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      marginLeft: 10,
    },
    discount: {
      marginLeft: 10,
      fontWeight: 'bold',
    },
    originalPrice: {
      textDecoration: 'line-through'
    },
    saleTotal: {
      display: 'inline',
      fontSize: 27,
      fontWeight: 'bold',
      color: isHotSale() ? theme.palette.primary.contrastText : theme.palette.primary.main
    },
    saleContainer: isHotSale() ? {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.shape.borderRadius,
    } : {},
    saleConditions: {
      textAlign: 'center',
      lineHeight: 1.2,
      flex: 1,
    },
    normalConditions: {
      textAlign: 'center',
      lineHeight: 1.2,
      flex: 1,
    },
    originalTotal: {
      display: 'inline',
      textDecoration: 'line-through',
      marginRight: 10,
    },
    totalContainer: {
      alignItems: 'baseline',
    },
  }),
);

type OrderDisplayProps = {
  order: OrderWithProducts;
  readOnly: boolean;
};

export default withErrorBoundary(OrderDisplay, { message: 'No se pudo cargar su orden.' });

function OrderDisplay({ order, readOnly }: OrderDisplayProps) {
  const classes = useStyles();
  const orderItemWithProducts = Order.getProducts(order);

  if (!orderItemWithProducts.length) {
    return null;
  }

  const total = orderItemWithProducts.reduce((acc, item) => {
    return item.product.price ? (acc + item.count * item.product.price) : acc;
  }, 0);

  if (!total) {
    return null;
  }

  const bestPriceTotal = orderItemWithProducts.reduce((acc, item) => {
    return item.product.price ? (acc + item.count * getBestProductPrice(item.product.price, item.product.tags, total, item.product.available)) : acc;
  }, 0);

  const bestMixTotal = orderItemWithProducts.reduce((acc, item) => {
    return item.product.price ? (acc + item.count * getBestMixPrice(item.product.price, item.product.tags, total, item.product.available)) : acc;
  }, 0);

  const bestMinInstallments = min(orderItemWithProducts.map((item) =>
    getBestProductInstallments(item.product.price, item.product.tags, total, item.product.available),
  )) || 0;

  const mixes = orderItemWithProducts.map(item =>
    getBestPromoMix(item.product.price, item.product.tags, total, item.product.available)
  );

  const bestMinMixDiscount = min(mixes.map((mix) => mix.discount)) || 0;
  const bestMinMixInstallments = min(mixes.map((mix) => mix.installments)) || 0;

  const showBestPrice = bestPriceTotal !== total && bestPriceTotal < bestMixTotal;
  const showInstallments = bestMinInstallments > Math.max(bestMinMixInstallments, 1);
  const showMix = bestMinMixDiscount !== 0 && bestMinMixInstallments !== 0;

  const showBasePrice = !showInstallments && !showBestPrice && !showMix;

  return <List className={classes.container}>
    <>
      { orderItemWithProducts.map((item) => (
        <React.Fragment key={ item.product.id }>
          <OrderItem item={ item } readOnly={ readOnly } total={ total }/>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
      { showInstallments ? (
        <ListItem className={ showMix || showBestPrice ? classes.totalContainer : classes.saleContainer}>
          <React.Fragment>
            <Typography variant="subtitle1" className={ classes.saleConditions }>{bestMinInstallments} cuotas sin interés<br/>Tarjeta bancaria Visa - Mastercard - Cabal - Amex</Typography>
            <Typography variant="subtitle1">{ formatPrice(total) }</Typography>
          </React.Fragment>
        </ListItem>
      ) : null}
      { showMix ? (
        <ListItem className={ classes.saleContainer }>
          <React.Fragment>
            <Typography variant="subtitle1" className={ classes.saleConditions }>🔥 {bestMinMixInstallments} cuotas sin interés<br/>Tarjeta bancaria Visa - Mastercard - Cabal - Amex</Typography>
            <Typography variant="subtitle1" className={ classes.saleTotal }>{ formatPrice(bestMixTotal) }</Typography>
          </React.Fragment>
        </ListItem>
      ) : null}
      { showBestPrice ? (
        <ListItem className={ showMix ? classes.totalContainer : classes.saleContainer }>
          <React.Fragment>
            <Typography variant="subtitle1" className={ showMix ? classes.normalConditions : classes.saleConditions }>Total a pagar con descuento<br/>Abonando en efectivo, débito o transferencia</Typography>
            <Typography variant="subtitle1" className={ showMix ? '' : classes.saleTotal }>{ formatPrice(bestPriceTotal) }</Typography>
          </React.Fragment>
        </ListItem>
      ) : null}
      { showBasePrice ? (
        <ListItem className={ classes.totalContainer }>
          <React.Fragment>
            <Typography variant="subtitle1" className={ classes.normalConditions }>Total a pagar en efectivo, débito, transferencia<br/>o tarjeta bancaria Visa - Mastercard - Cabal - Amex</Typography>
            <Typography variant="subtitle1">{ formatPrice(total) }</Typography>
          </React.Fragment>
        </ListItem>
      ) : null}
    </>
  </List>
}

type OrderItemProps = {
  item: OrderItemWithNonNullProduct;
  total: number;
  readOnly: boolean;
}
function OrderItem({ item, readOnly, total }: OrderItemProps) {
  const { setRoute } = useRouting();
  const { setProductCount, removeProduct } = useShoppingCart();
  const { product, count } = item;
  const classes = useStyles();

  const onOpenProduct = React.useCallback(() => {
    setRoute(buildProductRoute({ productId: item.product.id }));
  }, [ setRoute, product ]);

  const onIncreaseProductCount = React.useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    setProductCount(product.id, count + 1);
  }, [ setProductCount, product, count ]);

  const onDecreaseProductCount = React.useCallback(() => {
    if (count === 0) {
      // noinspection JSIgnoredPromiseFromCall
      removeProduct(product.id);
    } else {
      // noinspection JSIgnoredPromiseFromCall
      setProductCount(product.id, count - 1);
    }
  }, [ setProductCount, product, count ]);

  const discount = product.price ? getBestProductDiscount(product.price, product.tags, total, product.available) : 0;
  const finalPrice = product.price ? getBestProductPrice(product.price, product.tags, total, product.available) : 0;

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Badge badgeContent={ item.count } color="secondary">
          <Avatar src={ getCdnUri(item.product.uri, ImageVariant.Thumbnail) } />
        </Badge>
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            <Link onClick={ onOpenProduct }>
              { product.name }
            </Link>
            { readOnly ? null : (
              <React.Fragment>
                <IconButton onClick={ onDecreaseProductCount } className={ classes.itemButton }>
                  { count === 0 ? <HighlightOffRoundedIcon/> : <RemoveCircleOutlineRoundedIcon/> }
                </IconButton>
                <IconButton onClick={ onIncreaseProductCount } className={ classes.itemButton }>
                  <AddCircleOutlineRoundedIcon />
                </IconButton>
              </React.Fragment>
            )}
          </React.Fragment>
        }
        secondary={
          product.price && (discount ? <React.Fragment>
            <span className={ classes.originalPrice }>{ formatPrice(product.price) }</span>
            <span className={ classes.discount }>{ `-${discount * 100}%` }</span>
            <span className={ classes.salePrice }>{ formatPrice(finalPrice) }</span>
          </React.Fragment> : formatPrice(product.price))
        }
      />
    </ListItem>
  );
}
