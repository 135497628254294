import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import { categoriesTree } from '@del-alto/product-categories';
import { CategoryTreeNodeType } from "@del-alto/product-categories/lib";
import { buildCategoryRoute, useRouting } from "../routing";
import { isHotSale } from "@del-alto/shop-util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 210,
    },
    fullList: {
      width: 'auto',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    topLevelText: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: 5,
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    legendText: {
      color: theme.palette.primary.contrastText,
      padding: 5,
      fontStyle: 'italic',
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

interface MenuItem {
  label: string;
  legend?: string;
  depth: number;
  key: string | null;
}

const hotSaleFilter = isHotSale() ? (menuItem: MenuItem) => menuItem.key !== 'ingresos' : (menuItem: MenuItem) => menuItem.key !== 'hotSale';

type CategoriesMenuProps = {
  onCloseMenu: () => void;
}
export default function CategoriesMenu({ onCloseMenu }: CategoriesMenuProps) {
  const classes = useStyles();
  const flatten = categoriesTree.nodes.reduce((flat, sectionOrCategory) => {
    flat.push({
      label: sectionOrCategory.label,
      legend: sectionOrCategory.legend,
      key: sectionOrCategory.type === CategoryTreeNodeType.Category ? sectionOrCategory.key : null,
      depth: 0,
    });

    if (sectionOrCategory.type === CategoryTreeNodeType.Section) {
      flat.push(...sectionOrCategory.categories.map(category => ({
        label: category.label,
        legend: category.legend,
        key: category.key,
        depth: 1
      })));
    }
    return flat;
  }, [] as MenuItem[]).filter(hotSaleFilter);

  return (
    <div
      className={ classes.list }
    >
      <List>{ flatten.map(menuItem =>
        <MenuListItem
          key={ menuItem.key || menuItem.label }
          menuItem={ menuItem }
          onCloseMenu={ onCloseMenu }
        />
      )}</List>
    </div>
  );
}
type MenuListItemProps = {
  menuItem: MenuItem;
  onCloseMenu: () => void;
}
function MenuListItem({ menuItem, onCloseMenu }: MenuListItemProps) {
  const classes = useStyles();
  const { setRoute } = useRouting();
  const onClick = React.useCallback(() => {
    if (menuItem.key) {
      onCloseMenu();
      setRoute(buildCategoryRoute({ key: menuItem.key }));
    }
  }, [ setRoute, menuItem.key ]);

  if (menuItem.key) {
    return (
      <ListItem
        button
        onClick={ onClick }
        className={ menuItem.depth === 0 ? undefined : classes.nested }>
        <ListItemText
          primary={
            <span className={ menuItem.depth === 0 ? classes.topLevelText : undefined }>{ menuItem.label }</span>
          }
          secondaryTypographyProps={{
            className: classes.legendText
          }}
          {...menuItem.legend ? { secondary: menuItem.legend } : {}}
        />

      </ListItem>
    );
  }
  return (
    <ListItem
      className={ menuItem.depth === 0 ? undefined : classes.nested }>
      <ListItemText primary={ <span
        className={ menuItem.depth === 0 ? classes.topLevelText : undefined }>{ menuItem.label }</span> }/>
    </ListItem>
  );
}
