import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import hotSale20 from "./hot-sale-20.png";
import hotSale30 from "./hot-sale-30.png";
import classnames from 'classnames';

const useStyles = makeStyles(() =>
  createStyles({
    fixedIcon: {
      position: 'absolute',
    },
    iconLarge: {
      width: 86,
      height: 86,
      top: 20,
      right: 20,
    },
    iconSmall: {
      width: 45,
      height: 45,
      top: 5,
      right: 15,
    },
  }),
);

type HotSaleTagProps = {
  size?: 'small' | 'large';
  discount: number;
}

export default function HotSaleTag({ size = 'small', discount }: HotSaleTagProps) {
  const classes = useStyles();

  const src = (discount === 0.3 && hotSale30) ||
    (discount === 0.2 && hotSale20) ||
    null;

  return src ? (
    <img
      alt="WhatsApp"
      src={ src }
      className={ classnames(size === 'small' ? classes.iconSmall : classes.iconLarge, classes.fixedIcon) }
    />
  ) : <React.Fragment/>;
}
