import React from 'react';
import useImageDimensions from "../util/use-image-dimensions";
import { getCdnUri, ImageVariant, Dimensions } from "@del-alto/shop-util";
import { SceneWithTagsAndProducts } from "../types";

const CLIPPING_LIMIT = 0.3;

type UseScreenSizeParams = {
  scene: SceneWithTagsAndProducts | null;
  sceneFrameDimensions: Dimensions;
  imageVariant: ImageVariant;
};

export default function useSceneSize({ scene, sceneFrameDimensions, imageVariant }: UseScreenSizeParams) {
  const imageDimensions = useImageDimensions(scene && scene.uri ? getCdnUri(scene.uri, imageVariant) : null);

  return React.useMemo(() => {
    if (!imageDimensions) {
      return null;
    }

    return getPreferredSceneDimensions(imageDimensions, sceneFrameDimensions);
  }, [ sceneFrameDimensions, imageDimensions ]);
}

export function getPreferredSceneDimensions(imageDimensions: Dimensions, sceneFrameDimensions: Dimensions) {
  const {
    width: frameWidth,
    height: frameHeight,
  } = sceneFrameDimensions;

  const originalImageRatio = imageDimensions.width / imageDimensions.height;

  // maxClippedImageRadio = imageDimensions.width / (imageDimensions.height * (1 - CLIPPING_LIMIT))
  const maxClippedImageRatio = originalImageRatio / (1 - CLIPPING_LIMIT);
  const maxWidth = frameHeight * maxClippedImageRatio;
  const imageWidth = Math.min(maxWidth, frameWidth);
  const imageHeight = imageWidth / originalImageRatio;
  const imageTop = (frameHeight - imageHeight) / 2;
  const scaledImageDimensions = { width: imageWidth, height: imageHeight };
  const imageScale = imageWidth / imageDimensions.width;

  return {
    imageTop,
    imageScale,
    scaledImageDimensions,
  };
}
