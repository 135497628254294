import React from 'react';
import gql from 'graphql-tag';
import {
  Product,
  SceneWithTagsAndProducts,
  OrderWithProducts,
  OrderItemsInput,
  OrderStatusInput,
  Order,
  SearchResults,
  PlaylistWithScenesAndProducts,
  OrderStatus,
  Scene,
} from "./types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import getApolloClient from './app/apollo-client';
import { CustomerInfo, ProductTag } from "@del-alto/shop-util";
import createOrderCodeHint from "./order/create-code";
import { ProductSearchGroup } from "@del-alto/data-types";

const productInfoFragment = gql`
  fragment ProductInfoFragment on Product {
    id
    code
    name
    price
    uri
    keywords
    class
    description
    tags
    available
  }`;

const sceneSummaryFragment = gql`
  fragment SceneSummaryFragment on Scene {
    id
    label
    uri
    size {
      width
      height
    }
  }
`;

const sceneWithTagsAndProductsFragment = gql`
  fragment SceneWithTagsAndProductsFragment on Scene {
    ...SceneSummaryFragment
    tags {
      index
      geometry {
        x
        y
      }
      product {
        ...ProductInfoFragment
      }
    }
  }
  ${ productInfoFragment }
  ${ sceneSummaryFragment }
`;

const orderWithProductsFragment = gql`
  fragment OrderWithProducts on Order {
    createdAt
    status
    items {
      product {
        ...ProductInfoFragment
      }
      count
    }
    code
  }
  ${ productInfoFragment }
`;

type GetSceneQueryData = { scene: SceneWithTagsAndProducts };
type GetSceneQueryVariables = { id: string | null };
const getSceneQuery = gql`
  query getSceneProducts($id: ID!) {
    scene: getScene(id: $id) {
      ...SceneWithTagsAndProductsFragment
    }
  }
  ${ sceneWithTagsAndProductsFragment }
`;

type SearchProductsQueryData = { results: SearchResults };
type SearchProductQueryFilter = {
  q?: string | null;
  tags?: ProductTag[];
  include?: string[];
  exclude?: string[];
  available?: boolean;
  group?: ProductSearchGroup;
};
type SearchProductsQueryVariables = {
  filter: SearchProductQueryFilter | null;
};
const searchProductsQuery = gql`
  query getSceneProducts($filter: ProductSearchInput!) {
    results: searchProducts(input: $filter, from: 0, size: 500) {
      items {
        ...ProductInfoFragment
      }
      total
    }
  }
  ${ productInfoFragment }
`;

type SplitSearchProductsQueryData = {
  [k in ProductSearchGroup]: SearchResults;
};

interface GroupSearchProductsQueryFilter<TGroup extends ProductSearchGroup> extends SearchProductQueryFilter {
  group: TGroup;
}

type SplitSearchProductsQueryVariables = {
  newFilter: GroupSearchProductsQueryFilter<ProductSearchGroup.new> | null;
  reentryFilter: GroupSearchProductsQueryFilter<ProductSearchGroup.reentry> | null;
  restockedFilter: GroupSearchProductsQueryFilter<ProductSearchGroup.restocked> | null;
  availableFilter: GroupSearchProductsQueryFilter<ProductSearchGroup.available> | null;
  unavailableFilter: GroupSearchProductsQueryFilter<ProductSearchGroup.unavailable> | null;
};

const splitSearchProductsQuery = gql`
  query getSceneProducts(
    $newFilter: ProductSearchInput!,
    $reentryFilter: ProductSearchInput!,
    $restockedFilter: ProductSearchInput!,
    $availableFilter: ProductSearchInput!,
    $unavailableFilter: ProductSearchInput!
  ) {
    new: searchProducts(input: $newFilter, from: 0, size: 500) {
      items {
        ...ProductInfoFragment
      }
      total
    }
    reentry: searchProducts(input: $reentryFilter, from: 0, size: 500) {
      items {
        ...ProductInfoFragment
      }
      total
    }
    restocked: searchProducts(input: $restockedFilter, from: 0, size: 500) {
      items {
        ...ProductInfoFragment
      }
      total
    }
    available: searchProducts(input: $availableFilter, from: 0, size: 500) {
      items {
        ...ProductInfoFragment
      }
      total
    }
    unavailable: searchProducts(input: $unavailableFilter, from: 0, size: 500) {
      items {
        ...ProductInfoFragment
      }
      total
    }
  }
  ${ productInfoFragment }
`;

export type GetPlaylistQueryVariables = { id: string };
export type GetPlaylistQueryData = { playlist: PlaylistWithScenesAndProducts };
export const getPlaylistQuery = gql`
  query getPlaylist($id: ID!) {
    playlist: getPlaylist(id: $id) {
      items {
        size
        product {
          ...ProductInfoFragment
        }
        scene {
          ...SceneWithTagsAndProductsFragment
        }
      }
    }
  }
  ${ sceneWithTagsAndProductsFragment }
`;

export type ListOrdersWithProductsQueryData = {
  shoppingCart: OrderWithProducts;
  activeOrders: OrderWithProducts[];
};
const listOrdersWithProductsQuery = gql`
  query listOrdersWithProducts {
    shoppingCart: getLatestOrder {
      ...OrderWithProducts
    }
    activeOrders: getActiveOrders {
      ...OrderWithProducts
    }
  }
  ${ orderWithProductsFragment }
`;

export type UpdateOrderMutationData = { shoppingCart: OrderWithProducts };
export type UpdateOrderMutationVariables = { input: OrderItemsInput };
const createOrderMutation = gql`
  mutation createOrder($input: OrderInput!) {
    shoppingCart: createOrder(input: $input) {
      ...OrderWithProducts
    }
  }
  ${ orderWithProductsFragment }
`;

const updateOrderMutation = gql`
  mutation updateOrder($input: OrderInput!) {
    shoppingCart: updateOrder(input: $input) {
      ...OrderWithProducts
    }
  }
  ${ orderWithProductsFragment }
`;

type FindOrderByCodeQueryData = { order: OrderWithProducts };
type FindOrderByCodeQueryVariables = { code: string | null };
const findOrderByCodeQuery = gql`
  query findOrderByCode($code: String!) {
    order: findOrderByCode(code: $code) {
      ...OrderWithProducts
    }
  }
  ${ orderWithProductsFragment }
`;

type SetOrderStatusMutationData = { order: OrderWithProducts };
type SetOrderStatusMutationVariables = { input: OrderStatusInput };
const setOrderStatusMutation = gql`
  mutation updateOrderStatus($input: OrderStatusInput!) {
    order: updateOrderStatus(input: $input) {
      ...OrderWithProducts
    }
  }
  ${ orderWithProductsFragment }
`;

type GetProductQueryData = { product: Product };
type GetProductQueryVariables = { id: string | null };
const getProductQuery = gql`
  query getProduct($id: ID!) {
    product: getProduct(id: $id) {
      ...ProductInfoFragment
    }
  }
  ${ productInfoFragment }
`;

type GetProductRelationsQueryData = { substitute: SearchResults; complementary: SearchResults; scenes: { scene: Scene }[] };
type GetProductRelationsQueryVariables = { id: string; class: string; keywords: string[] };
const getProductRelationsQuery = gql`
  query getProductRelations($id: ID!, $class: String, $keywords: [String]) {
    substitute: findRelatedProducts(input: { keywords: $keywords, class: $class, limit: 8 }) {
      items {
        ...ProductInfoFragment
      }
    }
    complementary: findRelatedProducts(input: { keywords: $keywords, notClass: $class, limit: 7 }){
      items {
        ...ProductInfoFragment
      }
    }
    scenes: findScenesByProduct(productId: $id) {
      scene {
        ...SceneSummaryFragment
      }
    }
  }
  ${ productInfoFragment }
  ${ sceneSummaryFragment }
`;

export async function checkout(shoppingCart: OrderWithProducts, customerInfo: CustomerInfo) {
  const apolloClient = await getApolloClient();
  const hint = createOrderCodeHint();
  const code = shoppingCart.code;

  const result = await apolloClient.mutate<SetOrderStatusMutationData, SetOrderStatusMutationVariables>({
    mutation: setOrderStatusMutation,
    variables: {
      input: {
        createdAt: shoppingCart.createdAt,
        status: OrderStatus.Review,
        ...(code ? { code: code } : { hint }),
        customerInfo,
      }
    },
    refetchQueries: [ { query: listOrdersWithProductsQuery } ],
    awaitRefetchQueries: true,
  });

  return result.data!.order.code!;
}

export async function getOrderCode(shoppingCart: OrderWithProducts) {
  const code = shoppingCart.code;
  if (code) {
    return code;
  }

  const apolloClient = await getApolloClient();
  const hint = createOrderCodeHint();
  const result = await apolloClient.mutate<SetOrderStatusMutationData, SetOrderStatusMutationVariables>({
    mutation: setOrderStatusMutation,
    variables: {
      input: {
        createdAt: shoppingCart.createdAt,
        status: shoppingCart.status,
        hint,
      }
    },
    refetchQueries: [ { query: listOrdersWithProductsQuery } ],
    awaitRefetchQueries: true,
  });

  return result.data!.order.code!;
}

export const useListOrdersWithProductsQuery = () =>
  useQuery<ListOrdersWithProductsQueryData>(listOrdersWithProductsQuery);

export const useListOrdersWithProductsLazyQuery = () =>
  useLazyQuery<ListOrdersWithProductsQueryData>(listOrdersWithProductsQuery);

export async function updateOrder(order: Order) {
  const apolloClient = await getApolloClient();
  await apolloClient.mutate<UpdateOrderMutationData, UpdateOrderMutationVariables>({
    mutation: order.status === 'new' ? createOrderMutation : updateOrderMutation,
    variables: { input: {
        createdAt: order.createdAt,
        items: order.items,
    }},
    refetchQueries: [ { query: listOrdersWithProductsQuery } ],
    awaitRefetchQueries: true,
  });
}

type MarkVisitMutationVariables = {
  laid: string;
}
const markVisitMutation = gql`
  mutation markVisit($laid: String!) {
    visit: markVisit(laid: $laid)
  }
`;
export async function markVisit(laid: string) {
  const apolloClient = await getApolloClient();
  await apolloClient.mutate<{}, MarkVisitMutationVariables>({
    mutation: markVisitMutation,
    variables: {
      laid,
    },
  });
}

export const useGetProductQuery = (variables: GetProductQueryVariables) =>
  useQuery<GetProductQueryData, GetProductQueryVariables>(getProductQuery, { variables, skip: !variables.id });

export const useGetSceneQuery = (variables: GetSceneQueryVariables) =>
  useQuery<GetSceneQueryData, GetSceneQueryVariables>(getSceneQuery, { variables, skip: !variables.id });

export const useGetPlaylistQuery = (variables: GetPlaylistQueryVariables) =>
  useQuery<GetPlaylistQueryData, GetPlaylistQueryVariables>(getPlaylistQuery, { variables });

export const useSearchProductsQuery = (variables: SearchProductsQueryVariables) => {
  const [ singleSearchVariables, splitSearchVariables ] = React.useMemo(() => {
    if (variables.filter && (variables.filter.available === false || !!variables.filter.group)) {
      return [ variables, undefined ];
    }
    if (variables.filter) {
      return [
        undefined,
        {
          newFilter: { ...variables.filter, group: ProductSearchGroup.new },
          reentryFilter: { ...variables.filter, group: ProductSearchGroup.reentry },
          restockedFilter: { ...variables.filter, group: ProductSearchGroup.restocked },
          availableFilter: { ...variables.filter, group: ProductSearchGroup.available },
          unavailableFilter: { ...variables.filter, group: ProductSearchGroup.unavailable },
        } as SplitSearchProductsQueryVariables
      ];
    }
    return [ undefined, undefined ];
  }, [ variables ]);

  const { loading: singleSearchLoading, error: singleSearchError, data: singleSearchData } = useSingleSearchProductsQuery(singleSearchVariables);
  const { loading: splitSearchLoading, error: splitSearchError, data: splitSearchData } = useSplitSearchProductsQuery(splitSearchVariables);

  return React.useMemo(() => {
    let data;
    if (splitSearchData) {
      data = {
        results: {
          items: [
            ...splitSearchData.new.items,
            ...splitSearchData.reentry.items,
            ...splitSearchData.restocked.items,
            ...splitSearchData.available.items,
            ...splitSearchData.unavailable.items,
          ],
          total:
            splitSearchData.new.total +
            splitSearchData.reentry.total +
            splitSearchData.restocked.total +
            splitSearchData.available.total +
            splitSearchData.unavailable.total,
        },
      };
    } else {
      data = singleSearchData;
    }

    return {
      loading: singleSearchLoading || splitSearchLoading,
      error: singleSearchError || splitSearchError,
      data,
    };
  }, [ singleSearchLoading, splitSearchLoading, singleSearchError, splitSearchError, singleSearchData, splitSearchData]);
};

const useSingleSearchProductsQuery = (variables?: SearchProductsQueryVariables) =>
  useQuery<SearchProductsQueryData, SearchProductsQueryVariables>(searchProductsQuery, { variables, skip: !variables });

const useSplitSearchProductsQuery = (variables?: SplitSearchProductsQueryVariables) =>
  useQuery<SplitSearchProductsQueryData, SplitSearchProductsQueryVariables>(splitSearchProductsQuery, { variables, skip: !variables });

export const useFindOrderByCodeQuery = (variables: FindOrderByCodeQueryVariables) =>
  useQuery<FindOrderByCodeQueryData, FindOrderByCodeQueryVariables>(findOrderByCodeQuery, { variables, skip: !variables.code });

export const useGetProductsRelationsQuery = (variables: GetProductRelationsQueryVariables | null) =>
  useQuery<GetProductRelationsQueryData, GetProductRelationsQueryVariables>(getProductRelationsQuery, variables ?  { variables } : { skip: true });
