import once from 'lodash/fp/once';
import React from 'react';
import { Dimensions } from "@del-alto/shop-util";

let listeners = [] as ((dimension: Dimensions) => void)[];
let lastKnownDimensions: Dimensions = { width: 0, height: 0 };

export default function useScreenDimensions() {
  const [ dimensions, setDimensions ] = React.useState(lastKnownDimensions);

  React.useEffect(() => {
    registerListeners();
    setDimensions(lastKnownDimensions);
    listeners.push(setDimensions);
    return () => {
      listeners = listeners.filter(l => l !== setDimensions)
    }
  }, [ setDimensions ]);

  return dimensions;
}

function measureDimensions() {
  lastKnownDimensions = ({ width: Math.min(window.innerWidth, 600), height: window.innerHeight });
  listeners.forEach(l => l(lastKnownDimensions));
}

const registerListeners = once(() => {
  window.addEventListener('resize', () => {
    measureDimensions();
  });

  measureDimensions();
});
