import React from 'react';
import { createStyles, makeStyles } from "@material-ui/core/styles";
import useScreenDimensions from "../util/use-screen-dimentions";
import { Scene } from "../types";
import { FixedSizeList } from 'react-window';
import ScenePanel from "../playlist/scene-panel";
import { ImageVariant } from "@del-alto/shop-util";

type RelatedScenesStripProps = {
  scenes: Scene[];
};

const HORIZONTAL_PADDING = 10;
const SIZE = 250;
const DIMENSIONS = { width: SIZE, height: SIZE };

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginBottom: 25,
    },
  }),
);

export default function RelatedScenesStrip({ scenes }: RelatedScenesStripProps) {
  const classes = useStyles();
  const screenDimensions = useScreenDimensions();

  const itemRenderer = React.useMemo(() => {
    return function Product({ index, style }: { index: number; style: React.CSSProperties }) {
      const scene = scenes[ index ];
      return (
        <ScenePanel
          key={ scene.id }
          sceneId={ scene.id }
          dimensions={ DIMENSIONS }
          showBoardLink={ false }
          style={ style }
          imageVariant={ ImageVariant.Related }
        />
      );
    };
  }, [ scenes ]);

  const paddedWidth = screenDimensions.width - HORIZONTAL_PADDING * 2;

  const paddedDimensions = React.useMemo(() => {
    return { width: paddedWidth, height: SIZE }
  }, [ paddedWidth ]);

  return scenes.length === 1 ? (
    <ScenePanel
      sceneId={ scenes[0].id }
      dimensions={ paddedDimensions }
      showBoardLink={ false }
      imageVariant={ ImageVariant.Related }
    />
  ) : (
    <FixedSizeList
      className={ classes.root }
      width={ paddedWidth }
      height={ SIZE }
      itemSize={ SIZE + HORIZONTAL_PADDING }
      itemCount={ scenes.length }
      layout="horizontal"
    >{ itemRenderer }</FixedSizeList>
  );
}
