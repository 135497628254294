import React from 'react';

import { SceneScreen } from "../scene";
import { ProductScreen } from "../product";
import { PlaylistScreen } from "../playlist";
import { useRouting } from "../routing";
import { ShoppingCartScreen } from "../shopping-cart";
import { SearchScreen } from "../search";
import { CategoryScreen } from "../category";
import { OrderScreen } from "../order";

export default function ContentRouter() {
  const { route } = useRouting();
  return (
    (route.id === 'scene' && <SceneScreen sceneId={ route.sceneId } />) ||
    (route.id === 'product' && <ProductScreen productId={ route.productId }/>) ||
    (route.id === 'shopping-cart' && <ShoppingCartScreen />) ||
    (route.id === 'search' && <SearchScreen q={ route.q } />) ||
    (route.id === 'category' && <CategoryScreen category={ route.key } />) ||
    (route.id === 'playlist' && <PlaylistScreen playlistId={ route.playlistId } />) ||
    (route.id === 'order' && <OrderScreen orderCode={ route.orderCode } />) ||
    ( <PlaylistScreen /> )
  );
}
