import React from 'react';

const SCENE_ONLY_DURATION = 2000;
const EXPANDED_DOTS_DURATION = 1000;

export enum DotMode {
  Hidden,
  Normal,
  Expanded
}

type SceneAnimationState = {
  dotsMode: DotMode;
  expandBoardLink: boolean;
};

const INITIAL_STATE: SceneAnimationState = {
  dotsMode: DotMode.Hidden,
  expandBoardLink: false,
};

const INTERMEDIATE_STATE: SceneAnimationState = {
  dotsMode: DotMode.Expanded,
  expandBoardLink: true,
};

const FINAL_STATE: SceneAnimationState = {
  dotsMode: DotMode.Normal,
  expandBoardLink: true,
};

export default function useSceneAnimations(sceneId: string | null): SceneAnimationState {

  const [ animationState, setAnimationState ] = React.useState(INITIAL_STATE);

  React.useEffect(() => {
    setAnimationState(INITIAL_STATE);

    if (sceneId) {
      const timer1 = setTimeout(() => {
        setAnimationState(INTERMEDIATE_STATE);
      }, SCENE_ONLY_DURATION);

      const timer2 = setTimeout(() => {
        setAnimationState(FINAL_STATE);
      }, SCENE_ONLY_DURATION + EXPANDED_DOTS_DURATION);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      }
    }

    return undefined;
  }, [ sceneId ]);

  return animationState;
}
