import React from 'react';
import classnames from 'classnames';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";

import MeasureDimensions from "../components/measure-dimensions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGetProductQuery, useGetProductsRelationsQuery } from "../queries";
import BoundedSizeImage from "../components/bounded-size-image";
import BackButton from "../components/back-button";
import LoadingScreen from "../components/loading-screen";
import { Button } from "@material-ui/core";
import { SHOPPING_CART_ROUTE, useRouting } from "../routing";
import useShoppingCart from "../shopping-cart/use-shopping-cart";
import {
    formatPrice,
    getStockBehaviour,
    ProductTag,
    ImageVariant,
    getCdnUri,
    showDiscountPrice,
    showDiscountTag,
    isOnSale,
    getBestProductPrice,
    getBestMixPrice,
    getBestProductInstallments,
    getBestPromoMix, BEST_GROUP_DISCOUNT, getBestProductDiscount,
} from "@del-alto/shop-util";
import WAButton from "../components/wa-button";
import RelatedProductsStrip from "./related-products-strip";
import { Product, ProductSummary, Scene } from "../types";
import RelatedScenesStrip from "./related-scenes-strip";
import withErrorBoundary from "../components/with-error-boundary";
import Order from "../order/model";
import HotSaleTag from "../components/hot-sale-tag";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      width: '100%',
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
      padding: 7,
    },
    scrollable: {
      overflowX: 'hidden',
      overflowY: 'scroll',
      flex: 1,
    },
    imageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      paddingTop: 7,
    },
    priceRow: {},
    price: {
      marginRight: 10,
      display: 'inline-block',
    },
    salePrice: {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },
    saleConditions: {
      marginTop: -10,
    },
    originalPrice: {
      textDecoration: 'line-through'
    },
    stockDetail: {
      fontStyle: 'italic',
      fontSize: 12,
      display: 'inline-block',
    },
    productTag: {
      display: 'block',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      padding: '5px 15px 5px 15px',
      textTransform: 'uppercase',
      letterSpacing: 1,
      borderBottomLeftRadius: theme.shape.borderRadius * 2,
      borderTopLeftRadius: theme.shape.borderRadius * 2,
      fontSize: 10,
    },
    productTagsContainer: {
      position: 'absolute',
      bottom: 70,
      right: -7,
    },
    productName: {
      textTransform: 'uppercase',
      fontWeight: 300,
    },
    productCode: {
      padding: '0 10px',
      float: 'right',
    },
    productInfo: {
      padding: '10px 10px 20px',
    },
    shopButtonContainer: {
      margin: '10px 0',
    },
    productDescription: {
      whiteSpace: 'pre-line',
    },
    related: {
      margin: 10,
    },
    relatedTitle: {
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  }),
);

type ProductScreenProps = {
  productId: string;
};

export default function ProductScreen({ productId }: ProductScreenProps) {
  const classes = useStyles();

  const { loading, error, data } = useGetProductQuery({ id: productId });
  const { addProduct, shoppingCart } = useShoppingCart();
  const { setRoute } = useRouting();
  const product = data ? data.product : null;

  const isInCart = React.useMemo(() => (
    shoppingCart && Order.getProducts(shoppingCart).reduce((found, item) => found || item.product.id === productId, false)
  ), [ shoppingCart, productId ]);

  const onAddToCart = React.useCallback(() => {
    if (product) {
      // noinspection JSIgnoredPromiseFromCall
      addProduct(product.id);
    }
  }, [ addProduct, product ]);

  const onGoToCart = React.useCallback(() => {
    setRoute(SHOPPING_CART_ROUTE);
  }, [ setRoute ]);

  if (loading) {
    return (<LoadingScreen/>);
  }

  if (error || !product) {
    return (
      <div className={ classes.alertWrapper }>
        <Alert elevation={6} variant="filled" severity="error">{ error ? 'Hubo un error cargando datos' : 'No se encontró el producto' }</Alert>
      </div>
    );
  }

  const onSale = product.available && product.price && isOnSale(product.tags);
  const stockBehaviour = getStockBehaviour(product.tags);
  const showPrice = stockBehaviour !== ProductTag.Discontinued || product.available;
  const showDiscontinued = stockBehaviour === ProductTag.Discontinued && !product.available;
  const showUnavailable = stockBehaviour === "normal" && !product.available;
  const showComingSoon = stockBehaviour === ProductTag.Arrival && !product.available;
  const showNewArrival = stockBehaviour === ProductTag.Arrival && product.available;
  const showRequestable = stockBehaviour === ProductTag.Requestable && !product.available;
  const showImmediateDelivery = (stockBehaviour === ProductTag.Requestable || stockBehaviour === ProductTag.Discontinued) && product.available;

  const discount = product.price ? getBestProductDiscount(product.price, product.tags, product.price, product.available) : 0;
  const discountTag = product.price && showDiscountTag(product.price, product.tags, product.available);

  const bestPrice = product.price ? getBestProductPrice(product.price, product.tags, product.price, product.available) : 0;
  const bestMix = product.price ? getBestPromoMix(product.price, product.tags, product.price, product.available) : null;
  const bestMixPrice = product.price ? getBestMixPrice(product.price, product.tags, product.price, product.available) : 0;
  const bestInstallments = product.price ? getBestProductInstallments(product.price, product.tags, product.price, product.available) : 0;

  const showMix = bestMix && (bestMix.discount !== 0 && bestMix.installments !== 0);
  const showInstallments = bestInstallments > Math.max(bestMix?.installments || 0, 1);
  const showDiscount = showDiscountPrice(discount) && discount > (bestMix?.discount || 0);
  const showBasePrice = !showInstallments && !showMix && !showDiscount;
  const showBestGroupDiscount = BEST_GROUP_DISCOUNT && !showMix && !showDiscount;

  return (
    <div className={classes.container} key={ productId }>
      <MeasureDimensions className={ classes.scrollable } render={({ width, height }) => {
        const maxHeight = height - 140;
        const padding = 10;
        const maxWidth = width - padding * 2;

        return (
          <>
            <div className={ classes.imageWrapper }>
              <BoundedSizeImage width={ maxWidth } height={ maxHeight } src={ getCdnUri(product.uri, ImageVariant.Full) } label={ product.name } />
              <div className={classes.productTagsContainer}>
                { onSale ? <Typography variant="button" className={ classes.productTag } gutterBottom color="primary">Shop now!</Typography> : null }
                { (showComingSoon && <Typography variant="button" className={ classes.productTag } gutterBottom color="primary">Próximamente</Typography>) ||
                  (showNewArrival && <Typography variant="button" className={ classes.productTag } gutterBottom color="primary">Nuevo</Typography>) ||
                  (showRequestable && <Typography variant="button" className={ classes.productTag } gutterBottom color="primary">A pedido</Typography>) ||
                  (showImmediateDelivery && <Typography variant="button" className={ classes.productTag } gutterBottom color="primary">Entrega inmediata</Typography>) ||
                  null
                }
              </div>
              { discountTag ? <HotSaleTag size="large" discount={ discount }/> : null }
            </div>
            <div className={ classes.productInfo }>
              <Typography variant="caption" display="block" className={ classes.productCode }>{ product.code }</Typography>
              <Typography variant="h5" className={ classes.productName }>{ product.name }</Typography>
              { product.price ? (
                <>
                  <div className={ classes.priceRow }>
                    { showPrice ? (
                      <>
                        {showInstallments ? (
                          <>
                            <Typography variant="subtitle1" className={ classnames(classes.price) } gutterBottom>{ formatPrice(product.price) }</Typography>
                            <Typography variant="subtitle2" gutterBottom className={ classes.saleConditions }>{bestInstallments} cuotas sin interés de {formatPrice(product.price/bestInstallments)}</Typography>
                          </>
                        ) : null}
                        {showMix ? (
                          <>
                            { showInstallments ?
                              <Typography variant="subtitle1" gutterBottom className={ classnames(classes.price, classes.salePrice) }>{ formatPrice(bestMixPrice) }</Typography> :
                              <>
                                <Typography variant="subtitle1" className={ classnames(classes.price, classes.originalPrice) } gutterBottom>{ formatPrice(product.price) }</Typography>
                                <Typography variant="subtitle1" className={ classnames(classes.price) } gutterBottom>{ formatPrice(bestMixPrice) }</Typography>
                              </>
                            }
                            <Typography variant="subtitle2" gutterBottom className={ classes.saleConditions }>🔥 { bestMix!.discount * 100 }% off + {bestMix?.installments} cuotas sin interés.</Typography>
                          </>
                        ) : null}
                        {showDiscount ? (
                          <>
                            { (showInstallments || showMix) ?
                              <Typography variant="subtitle1" gutterBottom className={ classnames(classes.price, classes.salePrice) }>{ formatPrice(bestPrice) }</Typography> :
                              <>
                                <Typography variant="subtitle1" className={ classnames(classes.price, classes.originalPrice) } gutterBottom>{ formatPrice(product.price) }</Typography>
                                <Typography variant="subtitle1" className={ classnames(classes.price) } gutterBottom>{ formatPrice(bestPrice) }</Typography>
                              </>
                            }
                            <Typography variant="subtitle2" gutterBottom className={ classes.saleConditions }>{ discount * 100 }% de descuento abonando en efectivo, débito o transferencia.</Typography>
                          </>
                        ) : null}
                        {showBasePrice ? (
                          <Typography variant="subtitle1" gutterBottom className={ classnames(classes.price) }>{ formatPrice(product.price) }</Typography>
                        ) : null}
                        {showBestGroupDiscount ? (
                           BEST_GROUP_DISCOUNT!.installments ? (
                            <Typography variant="subtitle2" gutterBottom>
                              🔥 { BEST_GROUP_DISCOUNT!.discount * 100 }% off + {BEST_GROUP_DISCOUNT!.installments} cuotas sin interés
                              en compras a partir de { formatPrice(BEST_GROUP_DISCOUNT!.from) }
                            </Typography>
                           ) : (
                            <Typography variant="subtitle2" gutterBottom>
                              { BEST_GROUP_DISCOUNT!.discount * 100 }% de descuento en compras a partir de { formatPrice(BEST_GROUP_DISCOUNT!.from) }
                              <br/>Abonando en efectivo, débito o transferencia
                            </Typography>
                           )
                        ) : null}
                      </>
                    ) : null }
                    { (showDiscontinued || showUnavailable) ? (
                      <Typography variant="subtitle1" gutterBottom className={ classes.stockDetail }>{ 'Sin stock' }</Typography>
                    ) : null }
                    { showRequestable ? (
                      <Typography variant="subtitle1" gutterBottom className={ classes.stockDetail }>{ 'A pedido' }</Typography>
                    ) : null }
                    { showComingSoon ? (
                      <Typography variant="subtitle1" gutterBottom className={ classes.stockDetail }>{ 'Próximamente' }</Typography>
                    ) : null }
                  </div>
                  <div className={ classes.shopButtonContainer }>
                    { (isInCart && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={ onGoToCart }
                          >
                          Ir al carrito
                        </Button>
                      )) ||
                      (showPrice && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={ onAddToCart }
                        >
                          Agregar al carrito
                        </Button>
                      )) ||
                      null
                    }
                  </div>
                </>
              ) : null }
              <Typography variant="subtitle1" gutterBottom className={ classes.productDescription }>{ product.description }</Typography>
            </div>
            <RelatedProducts product={ product }/>
            <WAButton message={ `Hola, me interesa el siguiente producto: ${ product.name } (${ product.code }).` }/>
          </>
        );
      }}/>
      <BackButton />
    </div>
  );
}

const RelatedProducts = withErrorBoundary(({ product }: { product?: Product }) => {
  const classes = useStyles();

  const { loading: loading, error: error, data } = useGetProductsRelationsQuery((product && product.keywords && product.class) ? { id: product.id, keywords: product.keywords, class: product.class } : null);

  const { substitutes, complementary, scenes } = React.useMemo(() => {
    if (loading || error || !data) {
      return {
        substitutes: [] as ProductSummary[],
        complementary: [] as ProductSummary[],
        scenes: [] as Scene[],
      };
    }

    const substitutesWithoutThisProduct: ProductSummary[] = data.substitute ? data.substitute.items.filter(item => item.id !== product!.id) : [];
    return {
      substitutes: substitutesWithoutThisProduct,
      scenes: data.scenes ? data.scenes.map(({ scene }) => scene).filter(Boolean) : [],
      complementary: data.complementary ? data.complementary.items : [],
    };
  }, [ data ]);

  return (
    <div className={ classes.related }>
      <Typography variant="subtitle1" gutterBottom className={ classes.relatedTitle }>Productos relacionados</Typography>
      { loading ? (
        <CircularProgress />
      ) : null }
      { (substitutes.length || complementary.length) ? (
        <React.Fragment>
          { substitutes.length ? (
            <RelatedProductsStrip products={ substitutes } />
          ) : null }
          { scenes.length ? (
            <RelatedScenesStrip scenes={ scenes } />
          ) : null }
          { complementary.length ? (
            <RelatedProductsStrip products={ complementary } />
          ) : null }
        </React.Fragment>
      ) : null }
    </div>
  );
}, { message: 'No se pudieron encontrar productos relacionados.'});
