import React from 'react';
import { VariableSizeList, ListOnScrollProps } from 'react-window';

const scrollCache = { };

export default function useScrollCache(key: string, target: VariableSizeList | null)  {
  React.useEffect(() => {
    const cachedScroll = scrollCache[key];
    if (target && cachedScroll) {
      target.scrollTo(cachedScroll);
    }
  }, [ key, target ]);

  return React.useCallback((props: ListOnScrollProps) => {
    if (target) {
      scrollCache[key] = props.scrollOffset;
    }
  }, [ target ]);
}
