// @ts-nocheck
/* eslint-disable */
/*!
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
import Auth from '@del-alto/auth';
import { Observable, ApolloLink } from 'apollo-link';
import { print } from 'graphql/language/printer';

import { Signer } from './signer';
import * as Url from 'url';

const packageInfo = require("../../../package.json");

const SERVICE = 'appsync';
export const USER_AGENT_HEADER = 'x-amz-user-agent';
export const USER_AGENT = `aws-amplify/${packageInfo.version}`;

export default function createAuthLink({ url, region }: { url: string, region: string }) {
    return new ApolloLink((operation, forward) => {
        return new Observable(observer => {
            let handle;

            iamBasedAuth({
                region,
                url,
            }, operation, forward).then(observable => {
                handle = observable.subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                });
            });

            return () => {
                if (handle) {
                    handle.unsubscribe();
                }
            };
        });
    });
}

const iamBasedAuth = async ({ region, url }, operation, forward) => {
    const origContext = operation.getContext();

    const { accessKeyId, secretAccessKey, sessionToken } = await Auth.getCredentials();

    const { host, path } = Url.parse(url);

    const formatted = {
        ...formatAsRequest(operation, {}),
        service: SERVICE,
        region,
        url,
        host,
        path,
    };

    const { headers } = Signer.sign(
      formatted,
    { access_key: accessKeyId, secret_key: secretAccessKey, session_token: sessionToken },
      origContext.clockSkew,
    );

    operation.setContext({
        ...origContext,
        headers: {
            ...origContext.headers,
            ...headers,
            [USER_AGENT_HEADER]: USER_AGENT,
        },
    });

    return forward(operation);
};

const formatAsRequest = ({ operationName, variables, query }, options) => {
    const body = {
        operationName,
        variables: removeTemporaryVariables(variables),
        query: print(query)
    };

    return {
        body: JSON.stringify(body),
        method: 'POST',
        ...options,
        headers: {
            accept: '*/*',
            'content-type': 'application/json; charset=UTF-8',
            ...options.headers,
        },
    };
};

/**
 * Removes all temporary variables (starting with '@@') so that the signature matches the final request.
 */
const removeTemporaryVariables = (variables: any) =>
    Object.keys(variables)
        .filter(key => !key.startsWith("@@"))
        .reduce((acc, key) => {
            acc[key] = variables[key];
            return acc;
        }, {});
