export type ISO8601DateTime = string;

export enum ProductTag {
  Arrival = 'arrival',
  Discontinued = 'discontinued',
  Requestable = 'requestable',
  Sale = 'sale',
  HotSale = 'hotSale'
}

export enum ProductSearchGroup {
  new = 'new',
  reentry = 'reentry',
  restocked = 'restocked',
  available = 'available',
  unavailable = 'unavailable',
  discontinued = 'discontinued',
}
