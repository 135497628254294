import type { ProductTag } from '@del-alto/data-types';

export type StockBehaviourTag = ProductTag.Arrival | ProductTag.Discontinued | ProductTag.Requestable;
export type StockBehaviour = StockBehaviourTag | 'normal';

export type CustomerInfo = {
  contactMethod: ContactMethod;
  name?: string;
  phoneNumber?: string;
};

export enum ContactMethod {
  WhatsApp = 'whatsapp',
  Phone = 'phone',
}

export type Dimensions = {
  width: number;
  height: number;
}
