import React, { CSSProperties } from 'react';
import ScenePanel from "./scene-panel";
import { useGetPlaylistQuery } from "../queries";
import LoadingScreen from "../components/loading-screen";
import Alert from "@material-ui/lab/Alert/Alert";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SCENE_FRAME_PADDING, useSceneFrameDimensions } from "./use-scene-frame-dimensions";
import { VariableSizeList } from 'react-window';
import useScreenDimensions from "../util/use-screen-dimentions";
import ProductShowcase from "../components/product-showcase";
import { PlaylistItemSize, PlaylistItemWithScenesAndProducts } from "../types";
import useScrollCache from "../scroll-cache";
import { getPreferredSceneDimensions } from "./use-scene-size";
import { Dimensions, ImageVariant } from "@del-alto/shop-util";

const ROW_PADDING = 10;
const HORIZONTAL_PADDING = 20;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      overflowX: 'hidden',
      overflowY: 'scroll',
      padding: '0 7px',
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
    },
    smallRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    smallRowItem: {
      overflow: 'hidden',
    }
  })
);

const HOME_PLAYLIST = isFathersDay() ? '4f642c70-f059-4f91-9dca-1a5d96b49fb0' : 'e2178092-e08d-449a-9165-5f53a20e51e6';

function isFathersDay() {
  const time = Date.now();
  return time <  1718593200000;
}

type Row = {
  size: PlaylistItemSize;
  items: PlaylistItemWithScenesAndProducts[];
}
type PlaylistScreenProps = {
  playlistId?: string;
}
export default function PlaylistScreen({ playlistId = HOME_PLAYLIST }: PlaylistScreenProps) {
  const classes = useStyles();
  const { data, loading }  = useGetPlaylistQuery({ id: playlistId });
  const screenDimensions = useScreenDimensions();
  const sceneFrameDimensions = useSceneFrameDimensions();
  const [ scroller, setScroller ] = React.useState(null as VariableSizeList | null);
  const onScroll = useScrollCache(`playlist-${ playlistId }`, scroller);

  const rows = React.useMemo(() => {
    if (loading || !data) {
      return [];
    }

    const list = [] as Row[];

    const remaining = data.playlist.items.reduce((smallItems, item) => {
      const fullSize = item.scene && item.size !== PlaylistItemSize.Small;

      if (!fullSize) {
        smallItems.push(item);
      }

      if ((fullSize && smallItems.length) || smallItems.length === 2) {
        list.push({
          size: PlaylistItemSize.Small,
          items: smallItems,
        });
        smallItems = [];
      }

      if (fullSize) {
        list.push({
          size: PlaylistItemSize.Full,
          items: [ item ],
        });
      }

      return smallItems;
    }, [] as PlaylistItemWithScenesAndProducts[]);

    if (remaining.length) {
      list.push({
        size: PlaylistItemSize.Small,
        items: remaining,
      });
    }

    return list;
  }, [ loading, data ]);

  const smallSize = (sceneFrameDimensions.width - HORIZONTAL_PADDING) / 2;

  const itemRenderer = React.useMemo(() => {
    return function PlaylistRow({ index, style }: { index: number; style: CSSProperties }) {
      const row = rows[index];

      if (row.size === PlaylistItemSize.Full) {
        if (row.items.length !== 1 || !row.items[0].scene) {
          console.log('Row has wrong format');
          return null;
        }

        const scene = row.items[0].scene;

        let dimensions: Dimensions;
        if (scene.size) {
          const preferredSceneDimensions = getPreferredSceneDimensions(scene.size, sceneFrameDimensions);

          dimensions = {
            width: sceneFrameDimensions.width,
            height: preferredSceneDimensions.scaledImageDimensions.height + Math.min(preferredSceneDimensions.imageTop, 0) * 2
          };
        } else {
          dimensions = sceneFrameDimensions;
        }

        return (
          <div key={ index } style={ style }>
            <ScenePanel
              sceneId={ scene.id }
              dimensions={ dimensions }
              imageVariant={ImageVariant.Full}
              showBoardLink={ true }
            />
          </div>
        );
      }

      return <div className={ classes.smallRow } key={ index } style={ style }>{row.items.map((item, i) => {
        const { scene, product } = item;

        return <div className={ classes.smallRowItem } key={ i }>{
          (scene && (
            <ScenePanel
              sceneId={ scene.id }
              dimensions={{ width: smallSize, height: smallSize }}
              imageVariant={ImageVariant.Half}
              showBoardLink={ false }
              style={{ marginRight: (1 - i) * HORIZONTAL_PADDING, alignItems: 'center' }}
            />
          )) ||
          (product && (
            <ProductShowcase
              size={ smallSize }
              product={ product }
              style={{ marginRight: (1 - i) * HORIZONTAL_PADDING }}
              imageVariant={ ImageVariant.Half }
            />
          )) || null
        }</div>;
      })}
      </div>
    };
  }, [ rows, sceneFrameDimensions ]);

  React.useEffect(() => {
    if (scroller) {
      scroller.resetAfterIndex(0, true);
    }
  }, [ sceneFrameDimensions ]);

  const getRowSize = React.useCallback(index => {
      if (rows[ index ].size !== PlaylistItemSize.Full) {
        return sceneFrameDimensions.width / 2;
      }

      const row = rows[index];

      if (row.items.length !== 1 || !row.items[0].scene || !row.items[0].scene.size) {
        return sceneFrameDimensions.height + ROW_PADDING;
      }

      const preferredSceneDimensions = getPreferredSceneDimensions(row.items[0].scene.size, sceneFrameDimensions);
      return preferredSceneDimensions.scaledImageDimensions.height + Math.min(preferredSceneDimensions.imageTop, 0) * 2 + ROW_PADDING;
    },
    [ rows, sceneFrameDimensions ]
  );

  if (loading || !sceneFrameDimensions) {
      return (<LoadingScreen />);
  }

  if (!data) {
      return (
        <div className={ classes.alertWrapper }>
            <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
        </div>
      );
  }

  const rowsCount = rows.length;

  return (
    <div className={classes.root}>
      <VariableSizeList
        onScroll={ onScroll }
        ref={ setScroller }
        height={ screenDimensions.height - SCENE_FRAME_PADDING.height }
        width={ sceneFrameDimensions.width }
        itemCount={ rowsCount }
        itemSize={ getRowSize }
        itemData={ rows }
      >{ itemRenderer }</VariableSizeList>
    </div>
  );
}
