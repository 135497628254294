import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import imageFile from './logo.png';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 86,
      height: 86,
      borderRadius: 43,
      border: '2px solid white',
      boxShadow: theme.shadows[2],
      backgroundColor: 'white',
    }
  })
);

export default function Logo() {
  const classes = useStyles();

  return (
    <Avatar
      alt="Del Alto"
      src={imageFile}
      className={classes.logo}
    />
  );
}
