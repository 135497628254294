export const ORIGIN = window.location.origin;

export const CLIENT_ID = '1oitsiciak2g26d7agc28o7hu5';

export const AWS_REGION = 'us-east-1';

export const AUTH_DOMAIN = 'https://auth.delaltodigital.com';

export const REDIRECT_URI = ORIGIN;

export const AWS_COGNITO_CODE_QUERY_PARAM = 'code';

export const AWS_IDENTITY_POOL_ID = `${AWS_REGION}:d10bc2c0-647c-4faa-a937-a4993ae65525`;

export const CUSTOM_LINK_ATTRIBUTION_QUERY_PARAM = 'laid';
