import React from "react";

import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import SearchIcon from '@material-ui/icons/Search';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DEFAULT_ROUTE, EMPTY_SEARCH, useRouting } from "../../routing";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    buttonNavigationWrapper: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      boxShadow: theme.shadows[2],
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  }),
);

const navRoutes = {
  search: EMPTY_SEARCH,
  scenes: DEFAULT_ROUTE,
};

export default function BottomNavbar() {
  const { setRoute, route } = useRouting();
  const classes = useStyles();

  const onRouterChange = React.useCallback((e: React.ChangeEvent, value: string) => {
    setRoute(navRoutes[value] || DEFAULT_ROUTE);
  }, [ setRoute ]);

  const isScenes = !route.id || route.id === 'scene' || route.id === 'home';
  const navigationTabId = isScenes ? 'scene' : route.id;

  return (
    <div className={classes.buttonNavigationWrapper}>
      <BottomNavigation
        value={ navigationTabId }
        onChange={ onRouterChange }
      >
        <BottomNavigationAction value="scene" label="Vidrieras" icon={ <ImageRoundedIcon fontSize="default"/> } />
        <BottomNavigationAction value="search" label="Buscar" icon={ <SearchIcon fontSize="default"/> } />
      </BottomNavigation>
    </div>
  );
}

