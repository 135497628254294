export type Route =
  HomeRoute |
  ShoppingCartRoute |
  SceneRoute |
  SearchRoute |
  ProductRoute |
  CategoryRoute |
  PlaylistRoute |
  OrderRoute;

type HomeRoute = { id: 'home' };
type ShoppingCartRoute = { id: 'shopping-cart' };
type SceneRoute = { id: 'scene'; sceneId: string };
type SearchRoute = { id: 'search'; q: string };
type CategoryRoute = { id: 'category'; key: string };
type ProductRoute = { id: 'product'; productId: string };
type PlaylistRoute = { id: 'playlist'; playlistId: string };
type OrderRoute = { id: 'order'; orderCode: string };

export const HOME_ROUTE: HomeRoute = { id: 'home' };
export const SHOPPING_CART_ROUTE: ShoppingCartRoute = { id: 'shopping-cart' };
export const EMPTY_SEARCH: SearchRoute = buildSearchRoute({});

type ProductRouteParams = { productId: string }
export function buildProductRoute({ productId }: ProductRouteParams): ProductRoute {
  return {
    id: 'product',
    productId
  };
}

type OrderRouteParams = { orderCode: string }
export function buildOrderRoute({ orderCode }: OrderRouteParams): OrderRoute {
  return {
    id: 'order',
    orderCode
  };
}

type SceneRouteParams = { sceneId: string }
export function buildSceneRoute({ sceneId }: SceneRouteParams): SceneRoute {
  return {
    id: 'scene',
    sceneId
  };
}

type SearchRouteParams = { q?: string }
export function buildSearchRoute({ q }: SearchRouteParams): SearchRoute {
  return {
    id: 'search',
    q: q || '-'
  };
}

type CategoryRouteParams = { key: string }
export function buildCategoryRoute({ key }: CategoryRouteParams): CategoryRoute {
  return {
    id: 'category',
    key,
  };
}

export function buildOrderURI(orderCode: string) {
  return `https://www.delaltodigital.com/order/${orderCode}`;
}

export const DEFAULT_ROUTE = HOME_ROUTE;

export const ROUTES = [
  {
    id: 'home',
    path: '/',
  },
  {
    id: 'scene',
    path: '/scenes/:sceneId',
  },
  {
    id: 'product',
    path: '/product/:productId',
  },
  {
    id: 'search',
    path: '/search/:q',
  },
  {
    id: 'category',
    path: '/category/:key',
  },
  {
    id: 'playlist',
    path: '/pl/:playlistId',
  },
  {
    id: 'shopping-cart',
    path: '/cart',
  },
  {
    id: 'order',
    path: '/order/:orderCode',
  },
];
