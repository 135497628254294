import { ApolloClient } from 'apollo-client';
import { InMemoryCache, defaultDataIdFromObject, IdGetter, InMemoryCacheConfig } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, Operation } from 'apollo-link';
import { createHttpLink } from "apollo-link-http";

import { createAuthLink, createClockSkewLink } from '@del-alto/appsync-auth-apollo-link';

import { AWS_REGION, AWS_APPSYNC_ENDPOINT_URL } from "./constants";

const noIdFromObject: IdGetter = () => null;

type ApolloClientConfig = {
  config: InMemoryCacheConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorCallback?: (operation: Operation, message: string, extras: { [key: string]: any }) => void;
};
export default function createApolloClient({ config, errorCallback }: ApolloClientConfig) {
  const dataIdFromObject = config.dataIdFromObject || noIdFromObject;
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ operation, graphQLErrors, networkError }) => {
        const messages = [];
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            messages.push(`[GraphQL error]: Message: ${ message }, Location: ${ JSON.stringify(locations) }, Path: ${ path }`);
          });
        }

        if (networkError) {
          messages.push(`[Network error]: ${networkError}`);
        }

        if (errorCallback) {
          errorCallback(operation, messages.join('\n'), { graphQLErrors, networkError });
        }

        messages.forEach(message => console.log(message));
      }),
      createClockSkewLink(),
      createAuthLink({
        url: AWS_APPSYNC_ENDPOINT_URL,
        region: AWS_REGION,
      }),
      createHttpLink({
        uri: AWS_APPSYNC_ENDPOINT_URL,
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache({
      ...config,
      dataIdFromObject: (data) => dataIdFromObject(data) || defaultDataIdFromObject(data),
    }),
  });
}
