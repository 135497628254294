import generatedCategoriesTree from './categories-tree.json';
import { CategoryTreeNodeType, ProductCategoriesTree, ProductCategory } from "./types";

export * from './types';
const categoriesTree = (generatedCategoriesTree as ProductCategoriesTree);
const categoriesMap = categoriesTree.nodes.reduce((map, sectionOrCategory) => {
  if (sectionOrCategory.type !== CategoryTreeNodeType.Section) {
    map[ sectionOrCategory.key ] = sectionOrCategory;
  } else {
    sectionOrCategory.categories.map(category => {
      map[ category.key ] = category;
    })
  }
  return map;
}, {} as { [key: string]: ProductCategory });

export {
  categoriesTree,
  categoriesMap,
};
