// @ts-ignore
const GA_MEASUREMENT_ID = window.GA_MEASUREMENT_ID;

export function pageView(path: string) {
  gtag('config', GA_MEASUREMENT_ID, { 'page_path': path });
}

export function screenView(name: string) {
  gtag('event', 'screen_view', {
    'app_name': 'delaltodigital',
    'screen_name' : name,
  });
}

export function markEvent(event: string) {
  gtag('event', event);
}
