import {Dimensions} from "./types";

const S3_STATIC_ORIGIN = /^https:\/\/delalto-static.s3-sa-east-1.amazonaws.com\//;
const CLOUDFRONT_STATIC_ORIGIN = `https://static.delaltodigital.com/`;
const getPlaceholderUri = (text: string) => `https://via.placeholder.com/800x600?text=${text}`;

export enum ImageVariant {
    Original = 'orig',
    Thumbnail = 'thumb',
    Related = 'related',
    Full = 'full',
    Half = 'half',
}

export function getCdnUri(uri: string, variant: ImageVariant = ImageVariant.Original) {
    return uri && `${uri.replace(S3_STATIC_ORIGIN, CLOUDFRONT_STATIC_ORIGIN)}@${variant}`;
}

export function getPlaceholder(type?: 'product' | 'scene') {
    return getPlaceholderUri(type ? (type === 'product' ? 'PRODUCTO' : 'ESCENA') : '?');
}

export function generateShopProductLink(id: string) {
    return `https://www.delaltodigital.com/product/${id}`;
}

export function measureImage(uri: string): Promise<Dimensions> {
    return new Promise(resolve => {
        const img = new Image();

        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };

        img.src = uri;
    });
}
