import React from 'react';

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Dimensions, getCdnUri, ImageVariant } from "@del-alto/shop-util";
import MeasureDimensions from "../components/measure-dimensions";
import { useGetSceneQuery } from "../queries";
import BackButton from "../components/back-button";
import LoadingScreen from "../components/loading-screen";
import Alert from "@material-ui/lab/Alert/Alert";
import FixedSizeImage from "../components/fixed-size-image";
import { Product, Scene } from "../types";
import Board from "../components/board";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '0 7px',
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
      padding: 7,
    },
    scrollable: {
      paddingTop: 7,
      overflowX: 'hidden',
      overflowY: 'scroll',
      flex: 1,
    },
    boardColumn: {
      flex: 1,
    },
    sceneImageWrapper: {
      position: 'relative',
    },
  }),
);

type SceneScreenProps = {
  sceneId: string;
}

export default function SceneScreen({ sceneId }: SceneScreenProps) {
  const classes = useStyles();

  const { loading, error, data } = useGetSceneQuery({ id: sceneId });
  const scene = data ? data.scene : null;

  const products = React.useMemo(
    () => scene ?
      scene.tags.map(tag => tag.product).filter(Boolean) as Product[] :
      null,
    [ scene ]
  );

  if (loading) {
    return (<LoadingScreen />);
  }

  if (error || !scene) {
    return (
      <div className={ classes.alertWrapper }>
        <Alert elevation={6} variant="filled" severity="error">{ error ? 'Hubo un error cargando datos' : 'No se encontró la vidriera' }</Alert>
      </div>
    );
  }

  return (
    <div className={classes.container} >
      <MeasureDimensions className={ classes.scrollable } render={(dimensions) => {
        const { width, height } = getSceneSize(scene, dimensions);
        const sceneImageMargin = Math.max(height * .05, 15);

        return (
          <React.Fragment>
            <div className={ classes.sceneImageWrapper } style={{ height: height, marginBottom: sceneImageMargin }}>
              <FixedSizeImage width={ width } height={ height } src={ getCdnUri(scene.uri, ImageVariant.Full) } label={ scene.label }/>
            </div>
            { products ? ( <Board width={ width } products={ products }/>) : null }
          </React.Fragment>
        );
      }}/>
      <BackButton />
    </div>
  );
}

function getSceneSize(scene: Scene, dimensions: Dimensions) {
  if (!scene.size) {
    return {
      height: dimensions.height * 0.8,
      width: dimensions.width,
    };
  }

  const sceneAspectRatio = scene.size.height / scene.size.width;

  return {
    width: dimensions.width,
    height: sceneAspectRatio * dimensions.width,
  };
}
