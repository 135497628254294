import React from 'react';

export default function useFormValue(initialValue: string) {
  const [ value, setValue ] = React.useState(initialValue);

  const onSetValue = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, [ setValue ]);

  return [ value, onSetValue ] as [ string, (event: React.ChangeEvent<HTMLInputElement>) => void ];
}
