import React, { CSSProperties } from 'react';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BoundedSizeImage from "../bounded-size-image";
import {
  ImageVariant,
  getCdnUri,
  formatPrice,
  getStockBehaviour,
  ProductTag,
  getBestProductPrice,
  getBestProductDiscount,
  showDiscountTag,
  showDiscountPrice,
} from "@del-alto/shop-util";
import HotSaleTag from "../hot-sale-tag";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageWrapper: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      display: 'inline-block',
      position: 'relative',
    },
    lens: {
      overflow: 'hidden',
      position: 'relative',
      borderRadius: theme.shape.borderRadius * 2,
      backgroundColor: 'white',
    },
    lensSpacer: {
      display: 'inline-block',
      position: 'relative',
      float: 'left',
      marginRight: 7,
      marginBottom: 17.5,
    },
    infoTag: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius * 2,
      padding: '2px 10px',
      position: 'absolute',
      bottom: '0%',
      width: '80%',
      left: '10%',
      color: 'white',
      fontSize: 12,
      height: 28,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      alignItems: 'flex-start',
    },
    originalPrice: {
      textDecoration: 'line-through',
      paddingLeft: 5,
    },
    nameTag: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textTransform: 'capitalize',
      lineHeight: '12px',
      flex: 1,
      height: 24,
    },
    priceTag: {
      whiteSpace: 'nowrap',
      lineHeight: '12px',
      fontSize: 10,
    }
  }),
);

type ProductThumbnailProps = {
  size: number;
  src: string;
  name: string;
  price: number | null;
  available: boolean;
  imageVariant: ImageVariant;
  tags: ProductTag[];
  onClick: () => void;
  style?: CSSProperties;
};

export function HalfProductThumbnailSpacer({ size }: { size: number }) {
  const classes = useStyles();

  const styles = React.useMemo(() => ({
    width: size,
    height: size / 2,
  }), [ size ]);

  return (
    <div style={styles} className={classes.lensSpacer} />
  );
}

export default function ProductThumbnail({ size, src, name, price, onClick, style, available, tags, imageVariant }: ProductThumbnailProps) {
  const classes = useStyles();

  const lensStyles = React.useMemo(() => ({
    width: size,
    height: size,
  }), [ size ]);

  const imageSize = size;// * 0.70 / 0.95;
  const stockBehaviour = getStockBehaviour(tags);
  const showUnavailable = (stockBehaviour === "normal" || stockBehaviour === ProductTag.Discontinued) && !available;
  const originalPrice = price || 0;
  const discountTag = originalPrice && showDiscountTag(originalPrice, tags, available);
  const discount = originalPrice ? getBestProductDiscount(originalPrice, tags, originalPrice, available) : 0;
  const discountPrice = showDiscountPrice(discount);
  const finalPrice = originalPrice ? getBestProductPrice(originalPrice, tags, originalPrice, available) : 0;

  return (
    <div className={ classes.root } style={ style }>
      <div className={ classes.lens } style={ lensStyles } onClick={ onClick }>
        <div className={ classes.imageWrapper }>
          <BoundedSizeImage width={ imageSize } height={imageSize} src={getCdnUri(src, imageVariant)} label={name}/>
        </div>
      </div>
      <div className={ classes.infoTag }>
        <div className={classes.nameTag}>{ name.toLocaleLowerCase() }</div>
        {
          (showUnavailable && <div className={ classes.priceTag }>Sin stock</div>) ||
          (discountPrice &&
            <div className={ classes.priceTag }>
              { formatPrice(finalPrice) }
              <span className={classes.originalPrice}>{ formatPrice(originalPrice) }</span>
            </div>
          ) ||
          (originalPrice && <div className={ classes.priceTag }>{ formatPrice(originalPrice) }</div>) ||
          null
        }
      </div>
      { discountTag ? <HotSaleTag discount={originalPrice ? getBestProductDiscount(originalPrice, tags, originalPrice, available) : 0} /> : null }
    </div>
  );
}
