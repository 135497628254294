import React, { CSSProperties } from 'react';
import classnames from 'classnames';

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert/Alert";
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';

import Chip from '@material-ui/core/Chip';
import { buildProductRoute, buildSceneRoute, useRouting } from "../routing";
import { TagWithNonNullProduct } from "../types";
import LoadingScreen from "../components/loading-screen";
import { useGetSceneQuery } from "../queries";
import useSceneAnimations, { DotMode } from "./scene-animations";
import useSceneSize from "./use-scene-size";
import { getCdnUri, Dimensions } from "@del-alto/shop-util";
import useRevealedState from "./use-revealed-state";
import Scene from "../scene/model";
import { ImageVariant } from "@del-alto/shop-util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius * 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageClipper: {
      overflow: 'hidden',
      position: 'absolute',
      borderRadius: theme.shape.borderRadius * 2,
    },
    clippedImageWrapper: {
      position: 'absolute',
    },
    image: {
      borderRadius: theme.shape.borderRadius * 2,
    },
    backRegion: {
      position: 'absolute',
      top: 0,
      width: '50%',
      height: '100%',
    },
    imageWrapper: {
      position: 'relative',
      flex: 1,
    },
    alertWrapper: {
      width: '100%',
      top: 50,
      position: 'relative',
    },
    boardLink: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius * 4,
      height: 26,
      position: 'absolute',
      left: 20,
      transition: 'width 0.2s',
      width: 26,
      overflow: 'hidden',
    },
    boardLinkExpanded: {
      width: 120,
    },
    boardLinkContent: {
      backgroundColor: 'transparent',
    },
    productTag: {
      position: 'absolute',
      backgroundColor: 'white',
      transitionProperty: 'width height margin-left margin-top border-radius',
      transitionDuration: '0.2s',
    },
    dotsHidden: {
      width: 0,
      height: 0,
      borderRadius: 0,
      marginLeft: 0,
      marginTop: 0,
    },
    dotsExpanded: {
      width: 20,
      height: 20,
      borderRadius: 10,
      marginLeft: -10,
      marginTop: -10,
    },
    dotsNormal: {
      width: 8,
      height: 8,
      borderRadius: 4,
      marginLeft: -4,
      marginTop: -4,
    },
    nextButton: {
      position: 'absolute',
      backgroundColor: 'white',
      bottom: 50,
      right: 5,
    },
  }),
);

type ScenePanelProps = {
  sceneId: string;
  dimensions: Dimensions;
  showBoardLink: boolean;
  imageVariant: ImageVariant;
  style?: CSSProperties;
};
export default function ScenePanel({ sceneId, dimensions: sceneFrameDimensions, imageVariant, showBoardLink, style }: ScenePanelProps) {
  const classes = useStyles();

  const { loading, error, data } = useGetSceneQuery({ id: sceneId });

  const ref = React.useRef(null as null | HTMLDivElement);
  const revealed = useRevealedState(ref, 0.5);
  const { expandBoardLink, dotsMode } = useSceneAnimations(revealed ? sceneId : null);

  const { setRoute } = useRouting();

  const scene = (!loading && !error && data) ? data.scene : null;

  const openBoard = React.useCallback(() => {
    if (scene) {
      setRoute(buildSceneRoute({ sceneId: scene.id }));
    }
  }, [ setRoute, scene ]);

  const sceneSize = useSceneSize({ scene, sceneFrameDimensions, imageVariant });

  if (loading) {
    return (<LoadingScreen style={ style } ref={ ref } />);
  }

  if (!scene) {
    return (
      <div className={ classes.alertWrapper }>
        <Alert elevation={6} variant="filled" severity="error">Hubo un error cargando datos</Alert>
      </div>
    );
  }

  if (!sceneSize) {
    return (<LoadingScreen style={ style } ref={ ref } />);
  }

  const {
    imageTop,
    imageScale,
    scaledImageDimensions,
  } = sceneSize;

  const tags = Scene.getTags(scene);
  const mergedStyle = { ...sceneFrameDimensions, ...style };

  return (
    <div className={ classes.container } onClick={ openBoard } ref={ ref } style={mergedStyle}>
      <React.Fragment>
        <div className={classes.imageClipper} style={{ width: scaledImageDimensions.width, height: sceneFrameDimensions.height }} >
          <div className={classes.clippedImageWrapper} style={{ top: imageTop }}>
            <img
              alt={ scene.label }
              src={ getCdnUri(scene.uri, imageVariant) }
              style={{ width: scaledImageDimensions.width }}
              className={ classes.image }
              draggable={false}
            />
            { tags.map(tag => (
              <ProductTag key={ tag.index } tag={ tag } imageScale={ imageScale } dotMode={ dotsMode }/>
            ))}
          </div>
        </div>
        { showBoardLink && tags.length ? (
          <BoardLink expanded={ expandBoardLink } openBoard={ openBoard } style={{ bottom: Math.max(imageTop, 0) + 36 }}/>
        ) : null }
      </React.Fragment>
    </div>
  );
}

type ProductTagProps = {
  tag: TagWithNonNullProduct;
  dotMode: DotMode;
  imageScale: number;
};

const DOT_MODE_CLASS_NAMES = {
  [DotMode.Hidden]: 'dotsHidden',
  [DotMode.Expanded]: 'dotsExpanded',
  [DotMode.Normal]: 'dotsNormal',
};

function ProductTag({ tag, imageScale, dotMode }: ProductTagProps) {
  const classes = useStyles();
  const { setRoute } = useRouting();

  const tagStyle = React.useMemo(function () {
    return {
      top: tag.geometry.y * imageScale,
      left: tag.geometry.x * imageScale,
    } as CSSProperties;
  },[ tag, imageScale ]);

  const onClick = React.useCallback((e) => {
    setRoute(buildProductRoute({ productId: tag.product.id }));
    e.stopPropagation();
  }, [ setRoute, tag ]);

  const dotClass = classes[DOT_MODE_CLASS_NAMES[dotMode]];

  return (
    <div className={ classnames(classes.productTag, dotClass) } style={ tagStyle } onClick={ onClick }/>
  );
}

type BoardLinkProps = {
  expanded: boolean;
  openBoard: () => void;
  style: CSSProperties;
}

function BoardLink({ expanded, openBoard, style }: BoardLinkProps) {
  const classes = useStyles();

  const onClick = React.useCallback((e) => {
    openBoard();
    e.stopPropagation();
  }, [ openBoard ]);

  return (
    <div
      className={ classnames(classes.boardLink, expanded && classes.boardLinkExpanded) }
      style={style}
    >
      <Chip
        clickable
        color="primary"
        size="small"
        label="Ver productos"
        icon={ <LocalMallRoundedIcon /> }
        onClick={ onClick }
        className={ classes.boardLinkContent }
      />
    </div>
  );
}
