import React from "react";
import TopBar from "../components/top-bar";
import BottomNavbar from "../components/bottom-navbar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import { useToggle } from "@del-alto/shop-util";
import { CategoriesMenu } from "../categories";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    container: {
      position: 'absolute',
      height: 'calc(100% - 56px - 56px)',
      width: '100%',
      display: 'flex',
      maxWidth: 600,
      overflow: 'hidden',
      top: 56,
      [theme.breakpoints.up('sm')]: {
        top: 64,
      },
    },
  }),
);

type FrameProps = {
  children: React.ReactElement;
}

export default function Frame({ children }: FrameProps ) {
  const classes = useStyles();
  const [ drawerOpen, openDrawer, closeDrawer ] = useToggle();

  return (
    <div className={ classes.root }>
      <TopBar onOpenMenuDrawer={ openDrawer } />
      <div className={ classes.container }>
        { children }
      </div>
      <BottomNavbar />
      <Drawer anchor='left' open={ drawerOpen } onClose={ closeDrawer }>
        <CategoriesMenu onCloseMenu={ closeDrawer } />
      </Drawer>
    </div>
  );
}
