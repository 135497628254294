import React from 'react';
import { createStyles, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { OrderWithProducts } from "../types";
import { screenView } from "../gtag";
import WAButton from "../components/wa-button";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import useFormValue from "./use-form-value";
import { checkoutAndSendMessage, CheckoutType } from "./wa-checkout";
import { ContactMethod } from "@del-alto/shop-util";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
      borderBottomLeftRadius: theme.shape.borderRadius * 2,
      borderBottomRightRadius: theme.shape.borderRadius * 2,
      padding: '54px 10px 20px',
      textAlign: 'center',
      transition: 'top 1s',
      zIndex: 1,
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyItems: 'spaceAround',
    },
    phoneRow: {
      margin: '10px 0 0 8px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    phoneButton: {
      width: 50,
      alignContent: 'right',
    },
    phoneText: {
      flex: 1,
      textAlign: 'left',
      paddingLeft: 10,
    },
    waRow: {
      margin: '10px 8px 0 0',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    waButton: {
      width: 66,
      alignContent: 'right',
    },
    waText: {
      flex: 1,
      textAlign: 'right',
      paddingRight: 10,
    },
    title: {
      textAlign: 'center',
    },
    titleText: {
      textTransform: 'uppercase',
      padding: 0,
    },
  }),
);

type CheckoutDialogProps = {
  shoppingCart: OrderWithProducts;
  onCancel: () => void;
  open: boolean;
};

const VALID_PHONE_REGEXP = /^[\d\- ]+$/;
const PHONE_LENGTH = 10;

function isValidPhone(phone: string) {
  return VALID_PHONE_REGEXP.test(phone) &&
    phone.replace(/[^\d]+/g, '').length === PHONE_LENGTH;
}

export default function CheckoutDialog({ shoppingCart, onCancel, open }: CheckoutDialogProps) {
  const classes = useStyles();

  const [ name, onSetName ] = useFormValue('');
  const [ phone, onSetPhone ] = useFormValue('');
  const [ submitted, setSubmitted ] = React.useState(false);
  const [ loading, setLoading ] = React.useState(false);

  const handleClickAway = React.useCallback((e: React.MouseEvent<Document>) => {
    onCancel();
    e.stopPropagation()
  }, [ onCancel ]);

  React.useCallback(() => {
    if (open) {
      screenView('checkout');
    }
  }, [ open ]);

  const isNameValid = React.useMemo(() => {
    return name.length >= 3;
  }, [ name ]);

  const isPhoneValid = React.useMemo(() => {
    return isValidPhone(phone);
  }, [ phone ]);

  const isFormValid = React.useMemo(() => {
    return isNameValid && isPhoneValid;
  }, [ isNameValid, isPhoneValid ]);

  const onWACheckout = React.useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    if (isFormValid) {
      setLoading(true);
      await checkoutAndSendMessage(shoppingCart, CheckoutType.Explicit, {
        name,
        phoneNumber: phone,
        contactMethod: ContactMethod.WhatsApp,
      });
      onCancel();
      setLoading(false);
    } else {
      setSubmitted(true);
    }
  }, [ onCancel, isFormValid, name, phone, setSubmitted, setLoading ]);

  return (
    <ClickAwayListener onClickAway={ handleClickAway } mouseEvent={ open ? 'onClick' : false } touchEvent={ open ? 'onTouchEnd' : false }>
      <Paper className={classes.root} style={open ? {} : { top: '-100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={ classes.title }>
            <Typography variant="h5" className={ classes.titleText }>
              Datos de contacto
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={submitted && !isNameValid}
              variant="filled"
              color="primary"
              required
              fullWidth
              value={ name }
              onChange={ onSetName }
              size="small"
              label="Nombre y Apellido"
              id="name"
              name="name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={submitted && !isPhoneValid}
              variant="filled"
              color="primary"
              value={ phone }
              onChange={ onSetPhone }
              required
              fullWidth
              size="small"
              placeholder="299..."
              id="phone"
              label="Teléfono"
              name="phone"
              InputProps={{
                startAdornment: <InputAdornment position="start">+54 9</InputAdornment>,
              }}
            />
          </Grid>

          <Grid item xs={12} className={ classes.waRow }>
            <Typography variant="body1" className={ classes.waText }>
              Tocá el ícono de WhatsApp
              <br/>
              para confirmar tu pedido
              <br/>
              y contactarnos por ese medio.
            </Typography>
            <div className={ classes.waButton }>
              <WAButton onClick={ onWACheckout } floating={ true } size="small" disabled={ loading }/>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </ClickAwayListener>
  );
}
