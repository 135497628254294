import { ProductTag } from "@del-alto/shop-util";

export type ProductCategoriesTree = {
  nodes: (ProductCategory | CategoriesSection)[];
}

export type ProductCategory = {
  type: CategoryTreeNodeType.Category;
  key: string;
  label: string;
  legend?: string;
  filter: CategoryFilter;
}

export type CategoriesSection = {
  type: CategoryTreeNodeType.Section;
  label: string;
  legend?: string;
  categories: ProductCategory[];
}


export type CategoryFilter = {
  tags?: ProductTag[];
  category?: string;
  available?: boolean;
  include?: string[];
  exclude?: string[];
}

export enum CategoryTreeNodeType {
  Category = 'category',
  Section = 'section',
}
